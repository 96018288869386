import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import type { Client } from "@trainwell/features/legacy";
import { api } from "src/lib/trainwellApi";
import type { RootState } from "src/stores/store";

export const fetchClient = createAsyncThunk(
  "client/fetchClient",
  async (userId: string) => {
    const client = await api.clients.getOne(userId);

    Sentry.setUser({
      id: client.user_id,
      email: client.email,
      username: client.email,
    });

    return client;
  },
);

export const refetchClient = createAsyncThunk(
  "client/refetchClient",
  async (_, { getState }) => {
    const state = getState() as RootState;

    if (!state.client.client) {
      throw new Error("No client");
    }

    const response = await api.clients.getOne(state.client.client.user_id);

    return response;
  },
);

// Define a type for the slice state
interface ClientState {
  client: Client | undefined;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
}

// Define the initial state using that type
const initialState: ClientState = {
  client: undefined,
  status: "idle",
  error: undefined,
};

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    resetClient: () => initialState,
    setClient: (state, action: PayloadAction<Client>) => {
      state.client = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClient.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchClient.fulfilled, (state, action) => {
      console.log("Redux: Got client");
      state.status = "succeeded";

      const client = action.payload;

      state.client = client;
    });
    builder.addCase(fetchClient.rejected, (state) => {
      state.status = "failed";
    });
    builder.addCase(refetchClient.fulfilled, (state, action) => {
      console.log("Redux: refetched client");

      const client = action.payload;

      state.client = client;
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetClient, setClient } = clientSlice.actions;

export default clientSlice.reducer;
