import AccessTimeFilledRoundedIcon from "@mui/icons-material/AccessTimeFilledRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import { alpha, Box, Button, Card, Grid, Typography } from "@mui/material";
import { Navigate } from "@tanstack/react-router";
import {
  useProrationPreview,
  useSubscriptions,
  useSwitchSubscription,
} from "@trainwell/features/subscriptions";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import LoadingPage from "src/components/misc/LoadingPage";
import { useAppSelector } from "src/hooks/stateHooks";
import { hideChat } from "src/lib/frontChat";
import { formatCentsToDollars } from "src/lib/misc";

export function LiveTrainingPage() {
  const client = useAppSelector((state) => state.client.client);
  const [prorationDate] = useState(new Date().toISOString());
  const { priceId } = useOneOnOnePriceId();
  const {
    data: prorationPreview,
    isPending,
    isError,
  } = useProrationPreview({
    data: {
      userId: client?.user_id ?? "",
      newPriceId: priceId ?? "",
      prorationDate,
    },
  });
  const { data: subscriptions } = useSubscriptions({
    userId: client?.user_id ?? "",
  });
  const switchSubscription = useSwitchSubscription();

  const currentSubscription = subscriptions?.find(
    (plan) => plan.status === "active" || plan.status === "trialing",
  );

  const isInOneOnOneSessionTest = client?.tests?.includes(
    "dec_one_on_one_session_treatment",
  );

  useEffect(() => {
    hideChat();
  });

  if (!client) {
    return <LoadingPage message="Loading client" />;
  }

  if (
    !(
      isInOneOnOneSessionTest &&
      client?.account.membership.state === "active" &&
      client.account.dashboard.date_onboarded
    )
  ) {
    return <Navigate to="/" />;
  }

  if (isPending || !currentSubscription) {
    return <LoadingPage message="Loading upgrade info" />;
  }

  if (isError) {
    return <Typography>Error loading upgrade info</Typography>;
  }

  let intervalString = "per ";

  if (currentSubscription.interval_count > 1) {
    intervalString += `${currentSubscription.interval_count.toString()} `;
  }

  if (currentSubscription.interval === "year") {
    intervalString += "year";
  } else if (currentSubscription.interval === "week") {
    intervalString += "week";
  } else if (currentSubscription.interval === "day") {
    intervalString += "day";
  } else {
    intervalString += "month";
  }

  if (currentSubscription.interval_count > 1) {
    intervalString += "s";
  }

  const hasUpgraded =
    currentSubscription.stripe_price_id &&
    [
      "price_1QXaHGGWf3dtQxJnOnjbLCJ2",
      "price_1QXaFkGWf3dtQxJn6q230C74",
      "price_1QXaGnGWf3dtQxJnE4yP28Yf",
      "price_1QXoQCGWf3dtQxJnbP5SsNfz",
      "price_1QXoPkGWf3dtQxJnMilLZmio",
      "price_1QXoQ1GWf3dtQxJnDNpqOmFD",
    ].includes(currentSubscription.stripe_price_id);

  return (
    <Box
      sx={{
        py: 3,
        px: { xs: 2, sm: 3 },
        mb: { xs: 2, sm: 4 },
        maxWidth: "lg",
      }}
    >
      <Typography variant="h1" sx={{ mb: 2 }}>
        Realtime feedback, expert answers
      </Typography>
      <Typography sx={{ mb: 4 }}>
        Work towards your goals with{" "}
        <Typography
          component={"span"}
          sx={{
            color: (theme) => theme.palette.success.main,
            fontWeight: "bold",
          }}
        >
          dedicated live sessions
        </Typography>
      </Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid
          size={{
            xs: 12,
            sm: 4,
          }}
        >
          <Card
            variant="outlined"
            sx={{
              p: 2,
              mb: 2,
              backgroundColor: (theme) =>
                alpha(theme.palette.success.main, 0.1),
              borderColor: (theme) => alpha(theme.palette.success.main, 0.4),
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 1,
              }}
            >
              <VideocamRoundedIcon
                sx={{
                  color: (theme) => theme.palette.success.main,
                }}
              />
              <Typography sx={{ fontWeight: "bold", ml: 1 }}>
                Live training call
              </Typography>
            </Box>
            <Typography>
              Complete a guided live workout with real-time form feedback.
            </Typography>
          </Card>
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 4,
          }}
        >
          <Card
            variant="outlined"
            sx={{
              p: 2,
              mb: 2,
              backgroundColor: (theme) =>
                alpha(theme.palette.success.main, 0.1),
              borderColor: (theme) => alpha(theme.palette.success.main, 0.4),
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 1,
              }}
            >
              <AccessTimeFilledRoundedIcon
                sx={{
                  color: (theme) => theme.palette.success.main,
                }}
              />
              <Typography sx={{ fontWeight: "bold", ml: 1 }}>
                45-minute session
              </Typography>
            </Box>
            <Typography>
              Get moving…and get results! 45 minutes will allow your trainer to
              provide insights that improve the rest of your program.
            </Typography>
          </Card>
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 4,
          }}
        >
          <Card
            variant="outlined"
            sx={{
              p: 2,
              mb: 2,
              backgroundColor: (theme) =>
                alpha(theme.palette.success.main, 0.1),
              borderColor: (theme) => alpha(theme.palette.success.main, 0.4),
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 1,
              }}
            >
              <CalendarMonthRoundedIcon
                sx={{
                  color: (theme) => theme.palette.success.main,
                }}
              />
              <Typography sx={{ fontWeight: "bold", ml: 1 }}>
                One training call a month
              </Typography>
            </Box>
            <Typography>
              Dive in monthly to get regular feedback and capitalize on your
              program.
            </Typography>
          </Card>
        </Grid>
      </Grid>
      {!hasUpgraded && (
        <Card
          variant="outlined"
          sx={{
            p: 2,
          }}
        >
          <Typography variant="h2" sx={{ mb: 2 }}>
            Your plan
          </Typography>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid size={6}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                Before
              </Typography>
              <Typography
                sx={{
                  textDecoration: "line-through",
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                {formatCentsToDollars(currentSubscription.price!)}{" "}
                {intervalString}
              </Typography>
              <Typography
                sx={{
                  textDecoration: "line-through",
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                Next invoice on{" "}
                {format(
                  new Date(currentSubscription.nextInvoiceDate!),
                  "MMM d, yyyy",
                )}{" "}
                for{" "}
                {formatCentsToDollars(currentSubscription.nextInvoiceAmount!)}
              </Typography>
            </Grid>
            <Grid size={6}>
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
              >
                After
              </Typography>
              <Typography>
                {formatCentsToDollars(prorationPreview.new_price)}{" "}
                {intervalString}
              </Typography>
              <Typography>
                Next invoice on{" "}
                {format(
                  new Date(currentSubscription.nextInvoiceDate!),
                  "MMM d, yyyy",
                )}{" "}
                for {formatCentsToDollars(prorationPreview.new_price)}
              </Typography>
            </Grid>
          </Grid>
          <Typography sx={{ mb: 1 }}>
            Any upcoming pauses or cancellations will be removed.
          </Typography>
          <Button
            variant="contained"
            loading={switchSubscription.isPending}
            onClick={() => {
              if (!priceId || !client) {
                return;
              }

              switchSubscription.mutate({
                data: {
                  userId: client.user_id,
                  newPriceId: priceId,
                  prorationDate,
                  scheduleForNextBillingDate: false,
                },
              });
            }}
          >
            Upgrade to live training sessions now for{" "}
            {formatCentsToDollars(prorationPreview.amount_due)}
          </Button>
        </Card>
      )}
      {hasUpgraded && (
        <Card
          variant="outlined"
          sx={{
            p: 2,
          }}
        >
          <Typography variant="h2" sx={{ mb: 2 }}>
            Congratulations!
          </Typography>
          <Typography>
            Your trainer will reach out shortly to help you take advantage of
            your new live training sessions! Welcome to the next level!
          </Typography>
        </Card>
      )}
    </Box>
  );
}

function useOneOnOnePriceId(): { priceId: string | null } {
  const clientId = useAppSelector((state) => state.client.client?.user_id);
  const { data: subscriptions } = useSubscriptions({
    userId: clientId ?? "",
  });
  const currentSubscription = subscriptions?.find(
    (plan) => plan.status === "active" || plan.status === "trialing",
  );

  if (!currentSubscription) {
    return { priceId: null };
  }

  let monthlyPriceId = "price_1QXaHGGWf3dtQxJnOnjbLCJ2";
  let quarterlyPriceId = "price_1QXaFkGWf3dtQxJn6q230C74";
  let yearlyPriceId = "price_1QXaGnGWf3dtQxJnE4yP28Yf";

  if (import.meta.env.DEV) {
    // Stripe test mode
    monthlyPriceId = "price_1QXoQCGWf3dtQxJnbP5SsNfz";
    quarterlyPriceId = "price_1QXoPkGWf3dtQxJnMilLZmio";
    yearlyPriceId = "price_1QXoQ1GWf3dtQxJnDNpqOmFD";
  }

  let oneOnOnePriceId;

  if (
    currentSubscription.interval === "month" &&
    currentSubscription.interval_count === 1
  ) {
    oneOnOnePriceId = monthlyPriceId;
  } else if (
    currentSubscription.interval === "month" &&
    currentSubscription.interval_count === 3
  ) {
    oneOnOnePriceId = quarterlyPriceId;
  } else if (
    currentSubscription.interval === "year" &&
    currentSubscription.interval_count === 1
  ) {
    oneOnOnePriceId = yearlyPriceId;
  }

  return { priceId: oneOnOnePriceId };
}
