import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { IdTokenResult } from "firebase/auth";
import { api } from "src/lib/trainwellApi";
import { fetchClient } from "./clientSlice";

export const handleAuthSession = createAsyncThunk(
  "auth/handleAuthSession",
  async (authSessionId: string, { dispatch }) => {
    sessionStorage.setItem("authSessionId", authSessionId);

    const authSession = await api.accountSessions.getOne(authSessionId);

    dispatch(fetchClient(authSession.user_id));

    return authSession;
  },
);

export const handleAuthToken = createAsyncThunk(
  "auth/handleAuthToken",
  async (idTokenResult: IdTokenResult, { dispatch }) => {
    const userId = idTokenResult.claims.userId as string | undefined;

    if (userId) {
      dispatch(fetchClient(userId));
    } else {
      throw "Token not setup";
    }

    return userId;
  },
);

export const handleAuthWeak = createAsyncThunk(
  "auth/handleAuthWeak",
  async (userId: string, { dispatch }) => {
    dispatch(fetchClient(userId));

    return userId;
  },
);

// Define a type for the slice state
interface AuthState {
  authSessionId: string | undefined;
  authSessionStatus: "idle" | "loading" | "succeeded" | "failed";
  authType: "session" | "token" | "weak" | "loading";
}

// Define the initial state using that type
const initialState: AuthState = {
  authSessionId: undefined,
  authSessionStatus: "idle",
  authType: "loading",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetApp: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(handleAuthSession.pending, (state, action) => {
      console.log("Redux: handleAuthSession pending");

      state.authSessionStatus = "loading";

      const authSessionId = action.meta.arg;

      state.authSessionId = authSessionId;
      state.authType = "session";
    });
    builder.addCase(handleAuthSession.fulfilled, (state) => {
      console.log("Redux: handleAuthSession");
      state.authSessionStatus = "succeeded";
    });
    builder.addCase(handleAuthSession.rejected, (state) => {
      console.log("Redux: handleAuthSession rejected");

      state.authSessionStatus = "failed";

      state.authType = "loading";
    });
    builder.addCase(handleAuthToken.pending, (state) => {
      console.log("Redux: handleAuthToken pending");

      state.authType = "token";
    });
    builder.addCase(handleAuthToken.fulfilled, () => {
      console.log("Redux: handleAuthToken");
    });
    builder.addCase(handleAuthToken.rejected, (state) => {
      console.log("Redux: handleAuthToken rejected");

      state.authType = "loading";
    });
    builder.addCase(handleAuthWeak.pending, (state) => {
      console.log("Redux: handleAuthWeak pending");

      state.authType = "weak";
    });
    builder.addCase(handleAuthWeak.fulfilled, () => {
      console.log("Redux: handleAuthWeak");
    });
    builder.addCase(handleAuthWeak.rejected, (state) => {
      console.log("Redux: handleAuthWeak rejected");

      state.authType = "loading";
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetApp } = authSlice.actions;

export default authSlice.reducer;
