import { Box, Typography } from "@mui/material";
import type { SxProps, Theme } from "@mui/material/styles";
import { useBalance } from "@trainwell/features/clients";
import { useAppSelector } from "src/hooks/stateHooks";
import { formatCentsToDollars } from "src/lib/misc";

interface Props {
  sx?: SxProps<Theme>;
}

export function Balance({ sx = [] }: Props) {
  const userId = useAppSelector((state) => state.client.client?.user_id) ?? "";
  const { data } = useBalance({
    userId: userId,
  });

  const balance = data?.balance;

  if (!balance || balance >= 0) {
    return null;
  }

  return (
    <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Typography variant="h3">
        Account Credit: {formatCentsToDollars(Math.abs(balance))}
      </Typography>
      <Typography>
        This credit is a discount which will automatically be applied to your
        next invoice.
      </Typography>
    </Box>
  );
}
