import { LoadingPage } from "@trainwell/ui";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectSubscriptionOptions } from "src/slices/paymentSlice";
import Pay from "./Pay";

interface Props {
  trainerId: string;
}

export default function PayPageContainer({ trainerId }: Props) {
  const subOptions = useAppSelector(selectSubscriptionOptions);

  if (!subOptions) {
    return <LoadingPage message="Loading plan data" />;
  }

  return <Pay subOptions={subOptions} trainerId={trainerId} />;
}
