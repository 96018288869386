import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import { alpha, Box, Card, CardActionArea, Typography } from "@mui/material";
import { Navigate } from "@tanstack/react-router";
import WithAuth from "src/components/misc/WithAuth";
import { useAppSelector } from "src/hooks/stateHooks";
import { InvoiceList } from "../invoices/InvoiceList";
import { PaymentMethodList } from "../payment-methods/PaymentMethodList";
import { SubscriptionList } from "../subscriptions/SubscriptionList";
import { Balance } from "./Balance";

export function BillingPage() {
  const client = useAppSelector((state) => state.client.client);

  if (client?.account.plan.id === "freemium") {
    return <Navigate to="/upgrade-account" />;
  }

  return (
    <WithAuth>
      <Box
        sx={{
          py: 3,
          px: { xs: 2, sm: 3 },
          mb: { xs: 2, sm: 4 },
          maxWidth: "md",
        }}
      >
        <Card
          sx={{
            mb: 2,
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: 420,
          }}
          variant="outlined"
        >
          <CardActionArea
            href="https://go.trainwell.net/M7EFZvN8lGdB"
            sx={{
              px: 2,
              py: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>🎁 Get a gift card</Typography>
            <OpenInNewRoundedIcon fontSize="inherit" />
          </CardActionArea>
        </Card>
        <Typography variant="h1" sx={{ mb: 2 }}>
          Billing
        </Typography>
        <SubscriptionList sx={{ mb: 4 }} />
        <Balance sx={{ mb: 4 }} />
        <PaymentMethodList sx={{ mb: 4 }} />
        <InvoiceList />
      </Box>
    </WithAuth>
  );
}
