import { Box, Button, CircularProgress } from "@mui/material";

export interface StickyButtonProps {
  CTAText: string;
  disabled?: boolean;
  onClick?: () => void;
  loading?: boolean;
  submit?: boolean;
}

export default function CTAButton({
  CTAText,
  disabled,
  onClick,
  loading,
  submit,
}: StickyButtonProps) {
  const isStickyDesktopTest = false;

  return isStickyDesktopTest ? (
    <Box
      sx={{
        pt: 3.3,
        pb: 3,
        width: "100%",
        position: "sticky",
        zIndex: 10,
        bottom: 0,
        background:
          "linear-gradient(to top, #fff, #fff 89%, hsla(0,0%,100%,.3) 95%,hsla(0,0%,100%,0))",
      }}
    >
      <Button
        fullWidth
        onClick={submit ? undefined : onClick}
        disabled={disabled || loading}
        type={submit ? "submit" : "button"}
      >
        {loading ? <CircularProgress /> : CTAText}
      </Button>
    </Box>
  ) : (
    <Button
      fullWidth
      onClick={submit ? undefined : onClick}
      sx={{ mt: 4, mb: 4 }}
      type={submit ? "submit" : "button"}
      disabled={disabled || loading}
    >
      {loading ? <CircularProgress /> : CTAText}
    </Button>
  );
}
