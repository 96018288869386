import { Box, Typography } from "@mui/material";

export function MondaySurveyThankYou() {
  return (
    <Box
      sx={{
        minHeight: "calc(100vh - 68px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          pt: 4,
          px: { xs: 2, sm: 3 },
          mb: { xs: 2, sm: 4 },
          maxWidth: "sm",
        }}
      >
        <Typography variant="h1" sx={{ mb: 1, textAlign: "start" }}>
          Thank you! 🎉
        </Typography>
        <Typography sx={{ textAlign: "start", mb: 2 }}>
          Reviewing your week and keeping your trainer in the loop is a great
          way to work on being 100% consistent!
        </Typography>
        <Typography
          variant="body2"
          sx={{
            textAlign: "start",
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          You can close this page now :)
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          flex: 1,
          py: 2,
          maxWidth: "sm",
        }}
      >
        <img src="/assets/pass-the-mic.svg" width={"100%"} />
      </Box>
    </Box>
  );
}
