import { Box, Container, Link, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        p: 2,
        backgroundColor: (theme) => theme.palette.background.default,
        borderTop: 1,
        borderColor: "divider",
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid
            size={{
              xs: 12,
              sm: 4,
            }}
          >
            <Typography variant="h3" sx={{ mb: 1 }}>
              Company
            </Typography>
            <Typography variant="body2">
              <Link href="https://www.trainwell.net" target="_blank">
                Home
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link href="https://www.trainwell.net/about" target="_blank">
                About
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link href="https://www.trainwell.net/blog" target="_blank">
                Blog
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link href="https://www.trainwell.net/podcast" target="_blank">
                Podcast
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link href="https://www.trainwell.net/journeys" target="_blank">
                Journeys
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link href="https://www.trainwell.net/reviews" target="_blank">
                Reviews
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link
                href="https://www.trainwell.net/in-the-news"
                target="_blank"
              >
                In the news
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link href="https://www.trainwell.net/jobs" target="_blank">
                Jobs
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link href="https://www.trainwell.net/locations" target="_blank">
                Locations
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link href="https://shop.trainwell.net" target="_blank">
                Apparel
              </Link>
            </Typography>
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 4,
            }}
          >
            <Typography variant="h3" sx={{ mb: 1 }}>
              Resources
            </Typography>
            <Typography variant="body2">
              <Link href="https://www.trainwell.net/faq" target="_blank">
                FAQ
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link href="https://help.trainwell.net/" target="_blank">
                Help Center
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link href="https://www.trainwell.net/contact" target="_blank">
                Contact
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link href="https://www.trainwell.net/exercises" target="_blank">
                Exercise Library
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link
                href="https://www.trainwell.net/partnerships"
                target="_blank"
              >
                Partnerships
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link
                href="https://www.trainwell.net/ambassadors"
                target="_blank"
              >
                Ambassadors
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link
                href="https://www.trainwell.net/privacy-policy"
                target="_blank"
              >
                Privacy Policy
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link
                href="https://www.trainwell.net/terms-and-conditions"
                target="_blank"
              >
                Terms & Conditions
              </Link>
            </Typography>
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 4,
            }}
          >
            <Typography variant="h3">People love our app</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 1,
              }}
            >
              <img
                src="/assets/app-stars.svg"
                width={34}
                height={34}
                alt={"Facebook"}
              />
              <Typography variant="body2" sx={{ fontWeight: "bold", ml: 1 }}>
                4.9 - 5K Ratings
              </Typography>
            </Box>
            <Stack direction={"row"} spacing={2} sx={{ mb: 1 }}>
              <Link
                href="https://www.facebook.com/groups/trainwellteam"
                target="_blank"
              >
                <img
                  src="/assets/social/facebook.svg"
                  width={34}
                  height={34}
                  alt={"Facebook"}
                />
              </Link>
              <Link
                href="https://www.linkedin.com/company/trainwellfitness"
                target="_blank"
              >
                <img
                  src="/assets/social/linkedin.svg"
                  width={34}
                  height={34}
                  alt={"LinkedIn"}
                />
              </Link>
              <Link
                href="https://www.instagram.com/jointrainwell"
                target="_blank"
              >
                <img
                  src="/assets/social/instagram.svg"
                  width={34}
                  height={34}
                  alt={"Instagram"}
                />
              </Link>
              <Link href="https://www.reddit.com/r/trainwell" target="_blank">
                <img
                  src="/assets/social/reddit.svg"
                  width={34}
                  height={34}
                  alt={"Reddit"}
                />
              </Link>
              <Link
                href="https://www.youtube.com/@jointrainwell"
                target="_blank"
              >
                <img
                  src="/assets/social/youtube.svg"
                  width={34}
                  height={34}
                  alt={"YouTube"}
                />
              </Link>
            </Stack>
            <Link
              href="https://www.bbb.org/us/pa/pittsburgh/profile/personal-trainer/copilot-0141-71089524"
              target="_blank"
            >
              <img
                src="/assets/social/bbb.png"
                width={66}
                height={46}
                alt={"BBB"}
              />
            </Link>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              {"© trainwell"} {new Date().getFullYear()}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
