import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import type { SxProps, Theme } from "@mui/material/styles";
import { useInvoices } from "@trainwell/features/invoices";
import { useAppSelector } from "src/hooks/stateHooks";
import { InvoiceCell } from "./InvoiceCell";

interface Props {
  sx?: SxProps<Theme>;
}

export function InvoiceList({ sx = [] }: Props) {
  const userId = useAppSelector((state) => state.client.client?.user_id ?? "");
  const { data: invoices, isPending } = useInvoices({ userId: userId });

  return (
    <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Typography variant="h3" sx={{ mb: 2 }}>
        Invoices
      </Typography>
      {isPending && <CircularProgress />}
      <Stack direction="column" spacing={1}>
        {invoices?.map((invoice) => {
          return <InvoiceCell invoice={invoice} key={invoice.id} />;
        })}
      </Stack>
    </Box>
  );
}
