import CreditScoreRoundedIcon from "@mui/icons-material/CreditScoreRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Box, Button, Card, Chip, Stack, Typography } from "@mui/material";
import {
  useDeletePaymentMethod,
  useMakePaymentMethodDefault,
  usePaymentMethods,
  type PaymentMethod,
} from "@trainwell/features/payment-methods";
import { endOfMonth, isBefore, parse } from "date-fns";
import { useAppSelector } from "src/hooks/stateHooks";

const brands: Record<string, { name: string; iconSrc: string }> = {
  visa: {
    name: "Visa",
    iconSrc: "/assets/cards/visa.png",
  },
  amex: {
    name: "American Express",
    iconSrc: "/assets/cards/american_express.png",
  },
  discover: {
    name: "Discover",
    iconSrc: "/assets/cards/discover.png",
  },
  mastercard: {
    name: "Mastercard",
    iconSrc: "/assets/cards/mastercard.png",
  },
};

interface Props {
  paymentMethod: PaymentMethod;
}

export function PaymentMethodCard({ paymentMethod }: Props) {
  const client = useAppSelector((state) => state.client.client);
  const { data: paymentMethods } = usePaymentMethods({
    userId: client?.user_id ?? "",
  });
  const makePaymentMethodDefault = useMakePaymentMethodDefault();
  const deletePaymentMethod = useDeletePaymentMethod();

  const endOfMonthDate = endOfMonth(new Date());

  const expiresDate = paymentMethod.exp_month
    ? parse(
        `${paymentMethod.exp_month.toString().padStart(2, "0")}/${
          paymentMethod.exp_year
        }`,
        "MM/yyyy",
        new Date(),
      )
    : undefined;

  const isExpired = expiresDate ? isBefore(expiresDate, endOfMonthDate) : false;

  if (!paymentMethods) {
    return null;
  }

  const showMakeDefaultButton = !paymentMethod.is_default;
  const showDeleteButton =
    !paymentMethod.is_default && paymentMethods.length > 1;

  return (
    <Card variant="outlined" sx={{ p: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Stack direction={"row"} spacing={1} sx={{ alignItems: "center" }}>
            {paymentMethod.type === "link" ? (
              <img
                src={"/assets/cards/link.svg"}
                alt={"Link"}
                height={32}
                width={32}
              />
            ) : (
              paymentMethod.brand &&
              paymentMethod.brand in brands && (
                <img
                  src={brands[paymentMethod.brand].iconSrc}
                  alt={brands[paymentMethod.brand].name}
                  height={32}
                  width={32}
                />
              )
            )}
            {paymentMethod.type !== "link" && (
              <Typography sx={{ fontWeight: "bold" }}>
                {brands[paymentMethod.brand!]?.name ?? "Payment Method"}
              </Typography>
            )}
          </Stack>
          {paymentMethod.is_default && <Chip size="small" label="Default" />}
        </Box>
      </Box>
      {paymentMethod.type === "link" ? (
        <Typography
          variant="body2"
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          Link email: {paymentMethod.link_email}
        </Typography>
      ) : (
        <Typography
          variant="body2"
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          •••• •••• •••• {paymentMethod.last4}
        </Typography>
      )}
      {paymentMethod.type === "link" && (
        <Button
          size="small"
          variant="text"
          href="https://app.link.com/login"
          target="_blank"
        >
          Manage your Link payment method
        </Button>
      )}
      {paymentMethod.type !== "link" && (
        <Typography
          variant="body2"
          sx={{
            color: (theme) => theme.palette.text.secondary,
            display: "flex",
            alignItems: "self-end",
          }}
        >
          {isExpired && <WarningRoundedIcon sx={{ mr: 1 }} />}{" "}
          {isExpired ? "Expired" : "Expires"}{" "}
          {paymentMethod.exp_month!.toString().padStart(2, "0")}/
          {paymentMethod.exp_year}
        </Typography>
      )}
      {(showDeleteButton || showMakeDefaultButton) && (
        <Stack direction={"row"} spacing={1} alignItems="center" sx={{ mt: 1 }}>
          {showMakeDefaultButton && (
            <Button
              variant="text"
              onClick={() => {
                makePaymentMethodDefault.mutate({
                  data: {
                    paymentMethodId: paymentMethod.id,
                  },
                });
              }}
              loading={makePaymentMethodDefault.isPending}
              disabled={deletePaymentMethod.isPending}
              size="small"
              startIcon={<CreditScoreRoundedIcon />}
            >
              Make default
            </Button>
          )}
          {showDeleteButton && (
            <Button
              variant="text"
              color="error"
              onClick={() => {
                deletePaymentMethod.mutate({
                  data: {
                    paymentMethodId: paymentMethod.id,
                  },
                });
              }}
              loading={deletePaymentMethod.isPending}
              disabled={makePaymentMethodDefault.isPending}
              size="small"
              startIcon={<DeleteRoundedIcon />}
            >
              Delete
            </Button>
          )}
        </Stack>
      )}
    </Card>
  );
}
