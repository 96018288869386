import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import HotelIcon from "@mui/icons-material/Hotel";
import SportsMartialArtsIcon from "@mui/icons-material/SportsMartialArts";
import {
  alpha,
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CircularProgress,
  Grid,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSubmitMondaySurvey } from "@trainwell/features/clients";
import { useHabitDays } from "@trainwell/features/habit-days";
import { endOfWeek, format, parse, startOfWeek } from "date-fns";
import { fromZonedTime, toZonedTime } from "date-fns-tz";
import { useEffect, useState } from "react";
import LoadingPage from "src/components/misc/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { trackEvent } from "src/slices/analyticsSlice";
import { MondaySurveyThankYou } from "./MondaySurveyThankYou";

const days = ["S", "M", "T", "W", "T", "F", "S"];

export function MondaySurvey() {
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => state.client.client);
  const { data: habitDays, isPending: habitDaysPending } = useHabitDays({
    userId: client?.user_id ?? "",
    dateStart: fromZonedTime(startOfWeek(new Date()), "Etc/UTC").toISOString(),
    dateEnd: fromZonedTime(endOfWeek(new Date()), "Etc/UTC").toISOString(),
  });
  const [hasObstacles, setHasObstacles] = useState<boolean | null>(null);
  const [obstacleMessage, setObstacleMessage] = useState<string | null>(null);
  const [energyLevel, setEnergyLevel] = useState(50);
  const submitMondaySurvey = useSubmitMondaySurvey();

  useEffect(() => {
    if (!client?.user_id) {
      return;
    }

    void dispatch(
      trackEvent({
        event_type: "viewed_monday_survey",
      }),
    );
  }, [client?.user_id]);

  if (submitMondaySurvey.isSuccess) {
    return <MondaySurveyThankYou />;
  }

  if (!client) {
    return <LoadingPage message="Loading client" />;
  }

  return (
    <Box
      sx={{
        py: 3,
        px: { xs: 2, sm: 3 },
        mb: { xs: 2, sm: 4 },
        maxWidth: "sm",
      }}
    >
      <Typography variant="h1" sx={{ mb: 1 }}>
        Monday survey
      </Typography>
      <Typography
        variant="body2"
        sx={{ mb: 4, color: (theme) => theme.palette.text.secondary }}
      >
        This helps you and your trainer prepare for your week!
      </Typography>
      <Typography variant="h2" sx={{ mb: 2 }}>
        How are your energy levels?
      </Typography>
      <Stack spacing={2} direction="row" sx={{ alignItems: "center", mb: 4 }}>
        <HotelIcon />
        <Slider
          aria-label="Energy level"
          value={energyLevel}
          onChange={(_event, newValue) => {
            setEnergyLevel(newValue);
          }}
        />
        <SportsMartialArtsIcon />
      </Stack>
      <Typography variant="h2" sx={{ mb: 2 }}>
        Upcoming workouts
      </Typography>
      <Grid container columns={7} sx={{ mb: 2 }}>
        {days.map((day, i) => {
          const hasWorkouts = habitDays?.some((habitDay) =>
            habitDay.habit_tasks.some(
              (task) =>
                task.movement_type === "copilot_workout" &&
                !task.date_completed &&
                format(toZonedTime(habitDay.date, "Etc/UTC"), "EEEEE") === day,
            ),
          );

          const isToday = format(new Date(), "EEEEE") === day;

          return (
            <Grid
              key={i}
              size={1}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Avatar
                variant="circular"
                sx={{
                  backgroundColor: (theme) =>
                    isToday ? theme.palette.primary.main : "transparent",
                  width: 20,
                  height: 20,
                  mb: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: 11,
                    fontWeight: "bold",
                    color: (theme) =>
                      isToday
                        ? theme.palette.background.default
                        : theme.palette.text.secondary,
                  }}
                >
                  {day}
                </Typography>
              </Avatar>
              <Avatar
                variant="circular"
                sx={{
                  backgroundColor: (theme) => theme.palette.grey[200],
                  width: 24,
                  height: 24,
                }}
              >
                {hasWorkouts ? (
                  <FitnessCenterRoundedIcon
                    sx={{
                      fontSize: 16,
                      color: (theme) => theme.palette.text.secondary,
                    }}
                  />
                ) : (
                  <Box />
                )}
              </Avatar>
            </Grid>
          );
        })}
      </Grid>
      {habitDaysPending && <CircularProgress />}
      <Stack spacing={2} sx={{ mb: 4 }}>
        {habitDays?.map((habitDay) => {
          const workouts = habitDay.habit_tasks.filter(
            (task) =>
              task.movement_type === "copilot_workout" && !task.date_completed,
          );

          if (!workouts.length) {
            return null;
          }

          return (
            <Box key={habitDay.date as string}>
              <Typography sx={{ mb: 0.5 }}>
                {format(toZonedTime(habitDay.date, "Etc/UTC"), "EEEE, MMM do")}
              </Typography>
              <Stack spacing={1}>
                {workouts.map((workout, i) => {
                  const workoutTime = workout.time_target
                    ? format(
                        parse(workout.time_target, "HH:mm", new Date()),
                        "h:mm a",
                      )
                    : null;

                  return (
                    <Card
                      variant="outlined"
                      key={i}
                      sx={{ p: 2, display: "flex", alignItems: "center" }}
                    >
                      <Avatar
                        variant="circular"
                        sx={{
                          backgroundColor: (theme) => theme.palette.grey[200],
                          mr: 2,
                          width: 24,
                          height: 24,
                        }}
                      >
                        <FitnessCenterRoundedIcon
                          sx={{
                            fontSize: 16,
                            color: (theme) => theme.palette.text.secondary,
                          }}
                        />
                      </Avatar>
                      <Box>
                        <Typography
                          sx={{ fontWeight: "bold", lineHeight: 1.1 }}
                        >
                          {workout.name}
                        </Typography>
                        {workout.notes_coach && (
                          <Typography
                            variant="body2"
                            sx={{
                              mt: 1,
                              color: (theme) => theme.palette.text.secondary,
                              lineHeight: 1,
                            }}
                          >
                            {workout.notes_coach}
                          </Typography>
                        )}
                        {(workoutTime || workout.duration_estimated) && (
                          <Typography
                            variant="caption"
                            sx={{
                              color: (theme) => theme.palette.text.secondary,
                              lineHeight: 1,
                            }}
                          >
                            {[
                              workoutTime,
                              workout.duration
                                ? `${Math.round(workout.duration / 60)}m`
                                : undefined,
                            ]
                              .filter(Boolean)
                              .join(" • ")}
                          </Typography>
                        )}
                      </Box>
                    </Card>
                  );
                })}
              </Stack>
            </Box>
          );
        })}
      </Stack>
      <Typography variant="h2" sx={{ mb: 2 }}>
        Are there any obstacles to being 100% consistent this week?
      </Typography>
      <Grid container spacing={2}>
        <Grid size={6}>
          <CardActionArea
            onClick={() => {
              setHasObstacles(false);
            }}
            sx={{
              backgroundColor: (theme) =>
                hasObstacles === false
                  ? alpha(theme.palette.primary.main, 0.1)
                  : undefined,
              border: 1,
              borderColor: (theme) =>
                hasObstacles === false ? theme.palette.primary.main : "divider",
              borderRadius: 1,
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.primary.main,
                fontWeight: "bold",
              }}
            >
              No
            </Typography>
          </CardActionArea>
        </Grid>
        <Grid size={6}>
          <CardActionArea
            onClick={() => {
              setHasObstacles(true);
            }}
            sx={{
              backgroundColor: (theme) =>
                hasObstacles === true
                  ? alpha(theme.palette.primary.main, 0.1)
                  : undefined,
              border: 1,
              borderColor: (theme) =>
                hasObstacles === true ? theme.palette.primary.main : "divider",
              borderRadius: 1,
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.primary.main,
                fontWeight: "bold",
              }}
            >
              Yes
            </Typography>
          </CardActionArea>
        </Grid>
      </Grid>
      {hasObstacles === true && (
        <Box sx={{ mt: 2 }}>
          <TextField
            label="What are your obstacles?"
            fullWidth
            multiline
            value={obstacleMessage}
            onChange={(event) => {
              setObstacleMessage(event.target.value);
            }}
          />
        </Box>
      )}
      <Button
        fullWidth
        sx={{ mt: 4 }}
        onClick={() => {
          submitMondaySurvey.mutate(
            {
              userId: client.user_id,
              data: {
                has_blockers: hasObstacles ?? false,
                blockers: hasObstacles
                  ? (obstacleMessage ?? undefined)
                  : undefined,
                energy_level: energyLevel,
              },
            },
            {
              onSuccess: () => {
                window.scrollTo(0, 0);
              },
            },
          );
        }}
      >
        Submit
      </Button>
    </Box>
  );
}
