import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import type { SxProps, Theme } from "@mui/material/styles";
import { usePaymentMethods } from "@trainwell/features/payment-methods";
import { useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { PaymentMethodCard } from "./PaymentMethodCard";
import { PaymentMethodDialog } from "./PaymentMethodDialog";

interface Props {
  sx?: SxProps<Theme>;
}

export function PaymentMethodList({ sx = [] }: Props) {
  const client = useAppSelector((state) => state.client.client);
  const { data: paymentMethods, isPending } = usePaymentMethods({
    userId: client?.user_id ?? "",
  });
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);

  return (
    <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Typography variant="h3" sx={{ mb: 2 }}>
        Payment Methods
      </Typography>
      {isPending && <CircularProgress />}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {paymentMethods?.map((paymentMethod, i) => (
          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
            key={i}
          >
            <PaymentMethodCard paymentMethod={paymentMethod} />
          </Grid>
        ))}
      </Grid>
      <Button
        variant="text"
        startIcon={<AddRoundedIcon />}
        onClick={() => {
          setPaymentDialogOpen(true);
        }}
      >
        Add payment method
      </Button>
      <PaymentMethodDialog
        open={paymentDialogOpen}
        onClose={() => {
          setPaymentDialogOpen(false);
        }}
      />
    </Box>
  );
}
