import { CssBaseline, useTheme } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { TanStackRouterDevtools } from "@tanstack/react-router-devtools";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import type { ReactNode } from "react";
import { STRIPE_KEY } from "src/config/config";

const stripePromise = loadStripe(STRIPE_KEY);

interface Props {
  children: ReactNode;
}

export default function AppProviders({ children }: Props) {
  const theme = useTheme();

  return (
    <>
      <CssBaseline />
      <Elements
        stripe={stripePromise}
        options={{
          mode: "setup",
          currency: "usd",
          setupFutureUsage: "off_session",
          fonts: [
            {
              family: "CircularStd",
              src: "url(https://join.trainwell.net/fonts/circular-std-book.woff2)",
              style: "normal",
              weight: "400",
            },
          ],
          appearance: {
            variables: {
              colorPrimary: theme.palette.primary.main,
              colorDangerText: theme.palette.error.main,
              fontFamily: theme.typography.fontFamily,
              borderRadius: `${theme.shape.borderRadius}px`,
              colorText: theme.palette.text.primary,
              colorTextSecondary: theme.palette.text.secondary,
              fontWeightNormal: theme.typography.fontWeightRegular?.toString(),
            },
            rules: {
              ".Input": {
                borderColor: "#DEDEDE",
                borderWidth: "2px",
                boxShadow: "",
              },
              ".Tab": {
                borderColor: "#e9e9e9",
                borderWidth: "1px",
                boxShadow: "",
              },
            },
          },
        }}
      >
        <ConfirmProvider
          defaultOptions={{
            cancellationButtonProps: {
              variant: "text",
            },
          }}
        >
          <SnackbarProvider autoHideDuration={5000}>
            {children}
          </SnackbarProvider>
        </ConfirmProvider>
      </Elements>
      <ReactQueryDevtools buttonPosition="top-right" />
      <TanStackRouterDevtools position="bottom-right" />
    </>
  );
}
