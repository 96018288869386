import { Box, Typography } from "@mui/material";
import { useNavigate, useParams, useSearch } from "@tanstack/react-router";
import { useEffect } from "react";
import LoadingPage from "src/components/misc/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { handleAuthSession } from "src/slices/authSlice";

export function VerifySession() {
  const { sessionId, redirect: redirectPath } = useParams({ strict: false });
  const { redirect: redirectSearch } = useSearch({ strict: false });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authSessionStatus = useAppSelector(
    (state) => state.auth.authSessionStatus,
  );

  useEffect(() => {
    if (sessionId) {
      let redirect = redirectSearch || redirectPath;

      if (redirect && !redirect.startsWith("/")) {
        redirect = `/${redirect}`;
      }

      void dispatch(handleAuthSession(sessionId))
        .unwrap()
        .then(() => {
          if (redirect) {
            void navigate({ to: redirect });
          } else {
            void navigate({ to: "/" });
          }
        });
    }
  }, [dispatch, navigate, sessionId, redirectPath, redirectSearch]);

  if (authSessionStatus === "failed") {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        <Typography>The link you used is invalid</Typography>
      </Box>
    );
  }

  return <LoadingPage message="Hold on while we verify your link" />;
}
