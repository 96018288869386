import { Box, Typography } from "@mui/material";
import { Navigate } from "@tanstack/react-router";
import WithAuth from "src/components/misc/WithAuth";
import { useAppSelector } from "src/hooks/stateHooks";
import { TippingOptions } from "./TippingOptions";

export function TippingPage() {
  const client = useAppSelector((state) => state.client.client);

  if (
    client &&
    client.account.membership.state !== "active" &&
    client.account.membership.state !== "past_due"
  ) {
    return <Navigate to="/" />;
  }

  return (
    <WithAuth>
      <Box
        sx={{
          py: 3,
          px: { xs: 2, sm: 3 },
          mb: { xs: 2, sm: 4 },
          maxWidth: "md",
        }}
      >
        <Typography variant="h1" sx={{ mb: 2 }}>
          Tip your trainer!
        </Typography>
        <TippingOptions />
      </Box>
    </WithAuth>
  );
}
