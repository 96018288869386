import { CssBaseline, ThemeProvider } from "@mui/material";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRouter } from "@tanstack/react-router";
import { updateApiOptions } from "@trainwell/features";
import { getAuth } from "firebase/auth";
import { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import {
  SENTRY_DSN,
  TRAINWELL_API_DOMAIN,
  TRAINWELL_API_KEY,
} from "src/config/config";
import { store } from "src/stores/store";
import getTheme from "src/theme/theme";
import { routeTree } from "../routeTree.gen";
import { Router } from "./Router";

updateApiOptions({
  prefixUrl: TRAINWELL_API_DOMAIN,
  headers: {
    "api-key": TRAINWELL_API_KEY,
  },
  hooks: {
    beforeRequest: [
      async (request) => {
        const sessionId =
          typeof sessionStorage === "undefined"
            ? null
            : sessionStorage.getItem("authSessionId");

        if (sessionId) {
          request.headers.set("auth-session-id", sessionId);
        }

        const auth = getAuth();

        const tokenString = await auth.currentUser?.getIdToken();

        if (tokenString) {
          request.headers.set("Authorization", `Bearer ${tokenString}`);
        }
      },
    ],
  },
});

export const router = createRouter({
  routeTree,
  defaultPreload: "intent",
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
});

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [Sentry.tanstackRouterBrowserTracingIntegration(router)],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", TRAINWELL_API_DOMAIN],
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const rootElement = document.getElementById("root");

if (!rootElement) {
  throw new Error("Could not find root element");
}

const queryClient = new QueryClient();

const theme = getTheme("light");

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <StrictMode>
      <Suspense fallback={null}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Router />
            </ThemeProvider>
          </Provider>
        </QueryClientProvider>
      </Suspense>
    </StrictMode>,
  );
}
