import EditCalendarRoundedIcon from "@mui/icons-material/EditCalendarRounded";
import EventBusyRoundedIcon from "@mui/icons-material/EventBusyRounded";
import WavingHandRoundedIcon from "@mui/icons-material/WavingHandRounded";
import {
  Button,
  Card,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { type ClientMeeting } from "@trainwell/features/client-meetings";
import { format, isFuture } from "date-fns";

interface Props {
  call: ClientMeeting;
}

export function CallCard({ call }: Props) {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const isUpcoming = isFuture(call.date_start);

  const joinUrl = `https://meet.google.com/${call.conference_data.conferenceId}`;

  return (
    <Card sx={{ p: 2 }}>
      <Typography sx={{ fontWeight: "bold" }}>{call.name}</Typography>
      <Typography>
        {format(call.date_start, "MMM do, yyyy h:mm aaa")}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        {call.call_type === "onboarding" ? "Onboarding call" : "Check-in call"}
      </Typography>
      {isUpcoming && (
        <Typography sx={{ mt: 2 }}>
          Join url:{" "}
          <Link href={joinUrl} target={"_blank"}>
            {joinUrl}
          </Link>
        </Typography>
      )}
      {isUpcoming && (
        <Stack
          spacing={2}
          direction={isPhone ? "column" : "row"}
          sx={{ mt: 2 }}
        >
          <Button
            size="small"
            href={joinUrl}
            startIcon={<WavingHandRoundedIcon />}
            variant="contained"
            target="_blank"
          >
            Join meeting
          </Button>
          <Button
            size="small"
            href={`/reschedule/${call.id}`}
            startIcon={<EditCalendarRoundedIcon />}
            variant="outlined"
          >
            Reschedule
          </Button>
          <Button
            size="small"
            href={`/cancel/${call.id}`}
            startIcon={<EventBusyRoundedIcon />}
            variant="text"
            color="error"
          >
            Cancel
          </Button>
        </Stack>
      )}
    </Card>
  );
}
