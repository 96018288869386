import { Box, Button, Typography } from "@mui/material";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { useValidatePaymentMethod } from "@trainwell/ui";
import LoadingPage from "src/components/misc/LoadingPage";
import WithAuth from "src/components/misc/WithAuth";
import { useAppSelector } from "src/hooks/stateHooks";

export function PaymentMethodValidationPage() {
  const navigate = useNavigate();
  const userId = useAppSelector((state) => state.client.client?.user_id);
  const searchParams = useSearch({ strict: false });
  const { validationMessage } = useValidatePaymentMethod({
    userId: userId ?? "",
    searchParams: new URLSearchParams(searchParams),
    onSuccess: () => {
      navigate({ to: "/billing" });
    },
  });

  return (
    <WithAuth>
      {validationMessage ? (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            maxHeight: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ mb: 2 }}>{validationMessage}</Typography>
          <Button href="/billing">Go back</Button>
        </Box>
      ) : (
        <LoadingPage message="Validating payment method, stay on this page" />
      )}
    </WithAuth>
  );
}
