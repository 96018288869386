import { createFileRoute } from "@tanstack/react-router";
import { zodValidator } from "@tanstack/zod-adapter";
import { VerifySession } from "src/features/auth/VerifySession";
import { z } from "zod";

const searchSchema = z.object({
  redirect: z.string().optional(),
});

export const Route = createFileRoute("/verify-session/$sessionId")({
  validateSearch: zodValidator(searchSchema),
  component: VerifySession,
});
