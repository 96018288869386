import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import {
  alpha,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import type { SxProps, Theme } from "@mui/material/styles";
import { usePaymentMethods } from "@trainwell/features/payment-methods";
import { useSubscriptions } from "@trainwell/features/subscriptions";
import { useMemo, useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { PaymentMethodDialog } from "../payment-methods/PaymentMethodDialog";
import { RenewDialog } from "./RenewDialog";
import { SubscriptionCard } from "./SubscriptionCard";

interface Props {
  sx?: SxProps<Theme>;
}

export function SubscriptionList({ sx = [] }: Props) {
  const client = useAppSelector((state) => state.client.client);
  const { data: subscriptions, isPending } = useSubscriptions({
    userId: client?.user_id ?? "",
    queryConfig: {
      enabled: !!client?.user_id,
    },
  });
  const { data: paymentMethods } = usePaymentMethods({
    userId: client?.user_id ?? "",
  });
  const [showOldPlans, setShowOldPlans] = useState(false);
  const [paymentMethodDialogOpen, setPaymentMethodDialogOpen] = useState(false);
  const [renewDialogOpen, setRenewDialogOpen] = useState(false);

  const displayedPlans = useMemo(() => {
    if (showOldPlans) {
      return subscriptions;
    } else {
      return subscriptions?.filter(
        (sub) =>
          sub.status !== "canceled" && sub.status !== "incomplete_expired",
      );
    }
  }, [subscriptions, showOldPlans]);

  const isFreeAndCancelled =
    client?.account.plan.price === 0 &&
    client.account.membership.state === "cancelled";
  const isFreeAndActive =
    client?.account.plan.price === 0 &&
    client.account.membership.state === "active";

  const showRenewPlanButton =
    !isFreeAndActive &&
    subscriptions?.every(
      (plan) =>
        plan.status === "canceled" || plan.status === "incomplete_expired",
    );

  return (
    <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Typography variant="h3" sx={{ mb: 2 }}>
        Plans
      </Typography>
      {isPending && <CircularProgress />}
      {isFreeAndCancelled && (
        <Card
          variant="outlined"
          sx={{
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
            p: 1,
            display: "flex",
            my: 2,
            maxWidth: 420,
          }}
        >
          <InfoRoundedIcon color="primary" sx={{ mr: 1 }} />
          <Typography>
            Your free access has expired. You can renew your plan anytime.
          </Typography>
        </Card>
      )}
      {isFreeAndActive && (
        <Card
          variant="outlined"
          sx={{
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
            p: 1,
            display: "flex",
            alignItems: "center",
            my: 2,
            maxWidth: 420,
          }}
        >
          <InfoRoundedIcon color="primary" sx={{ mr: 1 }} />
          <Typography>You have free access to trainwell.</Typography>
        </Card>
      )}
      {showRenewPlanButton && (
        <Button
          variant="contained"
          onClick={() => {
            if (!paymentMethods || paymentMethods.length === 0) {
              setPaymentMethodDialogOpen(true);
            } else {
              setRenewDialogOpen(true);
            }
          }}
          sx={{ mb: 2 }}
        >
          Renew plan
        </Button>
      )}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {displayedPlans?.map((plan, i) => (
          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
            key={i}
          >
            <SubscriptionCard plan={plan} />
          </Grid>
        ))}
      </Grid>
      {subscriptions && subscriptions.length > 1 && (
        <Button
          variant="text"
          onClick={() => {
            setShowOldPlans(!showOldPlans);
          }}
          size="small"
          startIcon={
            showOldPlans ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />
          }
        >
          {showOldPlans ? "Hide old plans" : "Show old plans"}
        </Button>
      )}
      <PaymentMethodDialog
        open={paymentMethodDialogOpen}
        onClose={() => {
          setPaymentMethodDialogOpen(false);
          setRenewDialogOpen(true);
        }}
        renewPlanOnSuccess
      />
      <RenewDialog
        open={renewDialogOpen}
        onClose={() => {
          setRenewDialogOpen(false);
        }}
      />
    </Box>
  );
}
