import { Box, Button, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Navigate } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import LoadingPage from "src/components/misc/LoadingPage";
import { TrainerCard } from "src/features/switch-trainer/TrainerCard";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getPotentialTrainers } from "src/slices/switchTrainerSlice";

interface Props {
  selectCoach: (trainerId: string) => void;
}

export function SelectCoachPage({ selectCoach }: Props) {
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => state.client.client);
  const clientStatus = useAppSelector((state) => state.client.status);
  const potentialTrainers = useAppSelector(
    (state) => state.switchTrainer.potentialTrainers,
  );
  const forcedTrainerId = useAppSelector(
    (state) => state.switchTrainer.forcedTrainerId,
  );
  const [showAllCoaches, setShowAllCoaches] = useState(false);

  useEffect(() => {
    if (client?.user_id) {
      dispatch(getPotentialTrainers({ userId: client.user_id }));
    }
  }, [dispatch, client?.user_id]);

  let trainerIds = (potentialTrainers ?? []).map(
    (trainer) => trainer.trainer_id,
  );

  if (forcedTrainerId) {
    trainerIds = trainerIds.filter((id) => id !== forcedTrainerId);
    trainerIds.unshift(forcedTrainerId);
  }

  if (!showAllCoaches) {
    trainerIds = trainerIds.slice(0, 3);
  }

  const topChoiceIndex = 0;

  if (forcedTrainerId && !showAllCoaches) {
    trainerIds = trainerIds.slice(0, 1);
  }

  if (clientStatus === "loading" || clientStatus === "idle") {
    return <LoadingPage message="Loading client" />;
  }

  if (!potentialTrainers) {
    return <LoadingPage message="Finding your best trainers" />;
  }

  if (!client?.user_id) {
    return (
      <Container maxWidth="md">
        <Typography variant="h1" sx={{ mb: 4, textAlign: "center" }}>
          We ran into a problem finding your user id
        </Typography>
      </Container>
    );
  }

  if (
    client.account.membership.state !== "active" &&
    client.account.membership.state !== "past_due"
  ) {
    return <Navigate to="/" />;
  }

  return (
    <Container maxWidth="md" sx={{ my: 3 }}>
      <Typography variant="h1" sx={{ mb: 4, textAlign: "center" }}>
        Select your trainer
      </Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {trainerIds.length === 1 && (
          <Grid
            size={{
              xs: 12,
              sm: 4,
            }}
          />
        )}
        {trainerIds.map((trainerId, index) => (
          <Grid
            size={{
              xs: 12,
              sm: 4,
            }}
            key={trainerId}
          >
            <TrainerCard
              isTopChoice={index === topChoiceIndex}
              trainerID={trainerId}
              selectTrainerHandler={selectCoach}
              loading={false}
              buttonPrefix="Train with "
            />
          </Grid>
        ))}
      </Grid>
      {!showAllCoaches && potentialTrainers.length > 3 && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => {
              setShowAllCoaches(true);
            }}
          >
            See more trainers
          </Button>
        </Box>
      )}
    </Container>
  );
}
