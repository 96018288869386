import { autoScrollWindowForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import { extractClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { getReorderDestinationIndex } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index";
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import {
  Alert,
  alpha,
  Box,
  Button,
  CardActionArea,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { isBlank } from "@trainwell/features";
import { useHabitDays } from "@trainwell/features/habit-days";
import type { HabitTask, HabitTaskShadow } from "@trainwell/features/legacy";
import { useMoveWorkouts } from "@trainwell/features/week-plans";
import { addDays, addWeeks, endOfDay, format, startOfToday } from "date-fns";
import { fromZonedTime, toZonedTime } from "date-fns-tz";
import { useEffect, useRef, useState } from "react";
import LoadingPage from "src/components/misc/LoadingPage";
import { useAppSelector } from "src/hooks/stateHooks";
import { DayDroppable } from "./DayDroppable";
import { WorkoutGroup } from "./WorkoutGroup";

interface WorkoutGroupType {
  originalDate: string;
  workoutTasks: (HabitTask | HabitTaskShadow)[];
}

interface WorkoutGroupDay {
  date: string;
  workoutGroups: WorkoutGroupType[];
}

export function MoveWorkouts() {
  const client = useAppSelector((state) => state.client.client);
  const { data: habitDays, isPending: habitDaysPending } = useHabitDays({
    userId: client?.user_id ?? "",
    dateStart: fromZonedTime(startOfToday(), "Etc/UTC").toISOString(),
    dateEnd: fromZonedTime(
      endOfDay(addWeeks(new Date(), 1)),
      "Etc/UTC",
    ).toISOString(),
    queryConfig: {
      enabled: !!client,
    },
  });
  const [justification, setJustification] = useState("");
  const [makeChangePermanent, setMakeChangePermanent] = useState(false);
  const dndScrollableRef = useRef<HTMLDivElement | null>(null);
  const [workoutGroupDays, setWorkoutGroupDays] = useState<WorkoutGroupDay[]>(
    [],
  );
  const moveWorkouts = useMoveWorkouts();

  useEffect(() => {
    if (!habitDays) {
      return;
    }

    if (workoutGroupDays.length) {
      return;
    }

    const newWorkoutGroupDays: WorkoutGroupDay[] = habitDays.map((habitDay) => {
      const date = habitDay.date as string;
      const workoutTasks = habitDay.habit_tasks.filter(
        (task) =>
          task.movement_type === "copilot_workout" && !task.date_completed,
      );

      return {
        date,
        workoutGroups: workoutTasks.length
          ? [
              {
                originalDate: date,
                workoutTasks: workoutTasks,
              },
            ]
          : [],
      };
    });

    setWorkoutGroupDays(newWorkoutGroupDays);
  }, [habitDays, workoutGroupDays.length]);

  useEffect(() => {
    return autoScrollWindowForElements({
      canScroll: () => true,
    });
  }, []);

  useEffect(() => {
    return monitorForElements({
      onDrop(dropResult) {
        const source = dropResult.source;
        const sourceType = source.data.type as string | undefined;

        const target = dropResult.location.current.dropTargets[0];

        if (!target) {
          return;
        }

        const targetType = target.data.type as string | undefined;

        if (sourceType === "workout_group" && targetType === "empty_day") {
          console.log("DND: move workout group into empty day");

          const sourceIndex = source.data.index as number;
          const sourceDate = source.data.date as string;
          const targetDate = target.data.date as string;

          const newWorkoutGroupDays = [...workoutGroupDays];

          const sourceDayIndex = newWorkoutGroupDays.findIndex(
            (day) => day.date === sourceDate,
          );
          const targetDayIndex = newWorkoutGroupDays.findIndex(
            (day) => day.date === targetDate,
          );

          const [removed] = newWorkoutGroupDays[
            sourceDayIndex
          ].workoutGroups.splice(sourceIndex, 1);

          newWorkoutGroupDays[targetDayIndex].workoutGroups.push(removed);

          setWorkoutGroupDays(newWorkoutGroupDays);
        } else if (
          sourceType === "workout_group" &&
          targetType === "workout_group"
        ) {
          console.log("DND: reorder workout groups");

          const sourceIndex = source.data.index as number;
          const sourceDate = source.data.date as string;
          const targetIndex = target.data.index as number;
          const targetDate = target.data.date as string;

          const closestEdgeOfTarget = extractClosestEdge(target.data);

          let finishIndex = 0;

          if (sourceDate === targetDate) {
            finishIndex = getReorderDestinationIndex({
              startIndex: sourceIndex,
              closestEdgeOfTarget,
              indexOfTarget: targetIndex,
              axis: "vertical",
            });
          } else {
            finishIndex =
              closestEdgeOfTarget === "bottom" ? targetIndex + 1 : targetIndex;
          }

          const newWorkoutGroupDays = [...workoutGroupDays];

          const sourceDayIndex = newWorkoutGroupDays.findIndex(
            (day) => day.date === sourceDate,
          );
          const targetDayIndex = newWorkoutGroupDays.findIndex(
            (day) => day.date === targetDate,
          );

          const [removed] = newWorkoutGroupDays[
            sourceDayIndex
          ].workoutGroups.splice(sourceIndex, 1);

          newWorkoutGroupDays[targetDayIndex].workoutGroups.splice(
            finishIndex,
            0,
            removed,
          );

          setWorkoutGroupDays(newWorkoutGroupDays);
        }
      },
      canMonitor: ({ source }) =>
        ["workout_group", "empty_day"].includes(source.data.type as string),
    });
  }, [workoutGroupDays]);

  const moves = workoutGroupDays
    .map((day) => {
      return {
        from_date: day.workoutGroups[0]?.originalDate,
        to_date: day.date,
      };
    })
    .filter(
      (move) =>
        move.from_date !== move.to_date && move.to_date && move.from_date,
    );

  const isValid =
    moves.length > 0 &&
    workoutGroupDays.every((day) => {
      return day.workoutGroups.length <= 1;
    }) &&
    !isBlank(justification);

  if (!client) {
    return <LoadingPage message="Loading client" />;
  }

  return (
    <Box
      ref={dndScrollableRef}
      sx={{
        py: 3,
        px: { xs: 2, sm: 3 },
        mb: { xs: 2, sm: 4 },
        maxWidth: "sm",
      }}
    >
      <Typography variant="h1" sx={{ mb: 1 }}>
        Move workouts
      </Typography>
      <Typography
        variant="body2"
        sx={{ mb: 4, color: (theme) => theme.palette.text.secondary }}
      >
        A realistic plan that aligns with your goals and lifestyle, along with
        regular updates sent to your trainer, will strengthen your partnership
        and establish the foundation for long-term success.
      </Typography>
      {habitDaysPending && <CircularProgress />}
      <Stack spacing={2} sx={{ mb: 4 }}>
        {workoutGroupDays.map((workoutGroupDay) => {
          const date = workoutGroupDay.date;
          const isThisToday =
            fromZonedTime(startOfToday(), "Etc/UTC").toISOString() === date;
          const isThisTomorrow =
            fromZonedTime(
              addDays(startOfToday(), 1),
              "Etc/UTC",
            ).toISOString() === date;

          let dayLabel = format(toZonedTime(date, "Etc/UTC"), "EEEE, MMM do");

          if (isThisToday) {
            dayLabel = "Today";
          } else if (isThisTomorrow) {
            dayLabel = "Tomorrow";
          }

          const hasMultipleWorkoutGroups =
            workoutGroupDay.workoutGroups.length > 1;

          if (!workoutGroupDay.workoutGroups.length) {
            return (
              <Box key={date}>
                <Typography
                  sx={{
                    mb: 0.5,
                  }}
                >
                  {dayLabel}
                </Typography>
                <DayDroppable date={date} />
              </Box>
            );
          }

          return (
            <Box key={date}>
              <Typography sx={{ mb: 0.5 }}>{dayLabel}</Typography>
              {hasMultipleWorkoutGroups && (
                <Alert
                  severity="error"
                  sx={{
                    mb: 2,
                  }}
                >
                  You can't have more than one group of workouts per day.
                </Alert>
              )}
              <Stack spacing={2}>
                {workoutGroupDay.workoutGroups.map(
                  (workoutGroup, workoutGroupIndex) => {
                    return (
                      <WorkoutGroup
                        key={workoutGroupIndex}
                        index={workoutGroupIndex}
                        workoutTasks={workoutGroup.workoutTasks}
                        date={date}
                      />
                    );
                  },
                )}
              </Stack>
            </Box>
          );
        })}
      </Stack>
      <Typography variant="h2" sx={{ mb: 1.5 }}>
        Do you want your trainer to make these changes permanent?
      </Typography>
      <Grid container spacing={2}>
        <Grid size={6}>
          <CardActionArea
            onClick={() => {
              setMakeChangePermanent(false);
            }}
            sx={{
              backgroundColor: (theme) =>
                !makeChangePermanent
                  ? alpha(theme.palette.primary.main, 0.1)
                  : undefined,
              border: 1,
              borderColor: (theme) =>
                !makeChangePermanent ? theme.palette.primary.main : "divider",
              borderRadius: 1,
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.primary.main,
                fontWeight: "bold",
              }}
            >
              No
            </Typography>
          </CardActionArea>
        </Grid>
        <Grid size={6}>
          <CardActionArea
            onClick={() => {
              setMakeChangePermanent(true);
            }}
            sx={{
              backgroundColor: (theme) =>
                makeChangePermanent
                  ? alpha(theme.palette.primary.main, 0.1)
                  : undefined,
              border: 1,
              borderColor: (theme) =>
                makeChangePermanent ? theme.palette.primary.main : "divider",
              borderRadius: 1,
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.primary.main,
                fontWeight: "bold",
              }}
            >
              Yes
            </Typography>
          </CardActionArea>
        </Grid>
      </Grid>
      <Typography variant="h2" sx={{ mt: 2, mb: 1.5 }}>
        Let your trainer know why you're making these changes.
      </Typography>
      <Box>
        <TextField
          fullWidth
          placeholder="I'm making these changes because..."
          multiline
          value={justification}
          onChange={(event) => {
            setJustification(event.target.value);
          }}
        />
      </Box>
      <Button
        disabled={!isValid}
        loading={moveWorkouts.isPending}
        fullWidth
        color={moveWorkouts.isSuccess ? "success" : undefined}
        sx={{ mt: 4 }}
        startIcon={moveWorkouts.isSuccess && <CheckRoundedIcon />}
        onClick={() => {
          const moves = workoutGroupDays
            .map((day) => {
              return {
                from_date: day.workoutGroups[0]?.originalDate,
                to_date: day.date,
              };
            })
            .filter(
              (move) =>
                move.from_date !== move.to_date &&
                move.to_date &&
                move.from_date,
            );

          moveWorkouts.mutate({
            data: {
              user_id: client.user_id,
              moves: moves,
              justification: justification,
              make_permanent: makeChangePermanent,
            },
          });
        }}
      >
        {moveWorkouts.isSuccess ? "Saved" : "Save"}
      </Button>
    </Box>
  );
}
