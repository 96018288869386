import { Box, CircularProgress, Typography } from "@mui/material";

interface Props {
  message?: string;
}

export default function LoadingComponent({ message }: Props) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <CircularProgress sx={{ mb: 2 }} />
      <Typography>
        {message ? message : "Our tech is warming up 🔥 😎"}
      </Typography>
    </Box>
  );
}
