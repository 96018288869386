import AddRoundedIcon from "@mui/icons-material/AddRounded";
import LockIcon from "@mui/icons-material/Lock";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import {
  alpha,
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useQueryClient } from "@tanstack/react-query";
import { useSearch } from "@tanstack/react-router";
import { usePaymentMethods } from "@trainwell/features/payment-methods";
import { useAddPaymentMethod } from "@trainwell/ui";
import { useEffect, useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { hideChat, showChat } from "src/lib/frontChat";

interface Props {
  open: boolean;
  onClose: () => void;
  renewPlanOnSuccess?: boolean;
}

export function PaymentMethodDialog({
  open,
  onClose,
  renewPlanOnSuccess,
}: Props) {
  const client = useAppSelector((state) => state.client.client);
  const { data: paymentMethods } = usePaymentMethods({
    userId: client?.user_id ?? "",
  });
  const defaultPaymentMethod = paymentMethods?.find((pm) => pm.is_default);
  const [makeDefault, setMakeDefault] = useState(
    !defaultPaymentMethod ? true : (renewPlanOnSuccess ?? false),
  );
  const queryClient = useQueryClient();
  const searchParams = useSearch({ strict: false });

  const {
    addPaymentMethod,
    paymentErrorMessage,
    validationMessage,
    submitting,
  } = useAddPaymentMethod({
    userId: client?.user_id ?? "",
    searchParams: new URLSearchParams(searchParams),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["payment_methods"],
      });

      onClose();
    },
  });

  const stripe = useStripe();
  const elements = useElements();

  // Hide chat when dialog is open
  useEffect(() => {
    if (open) {
      hideChat();
    } else {
      showChat({
        clientName: client?.full_name ?? "",
        userId: client?.user_id ?? "",
      });
    }
  }, [open]);

  function handleClose() {
    onClose();
  }

  if (!elements) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (submitting) {
          return;
        }

        handleClose();
      }}
      fullWidth
      maxWidth="sm"
      slotProps={{
        paper: {
          sx: {
            width: "calc(100% - 16px)",
            mx: 1,
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pr: 3,
        }}
      >
        <DialogTitle sx={{ px: { xs: 1, sm: 3 } }}>
          Add payment method
        </DialogTitle>
        <Tooltip title={"Secured by Stripe & SSL encryption"}>
          <LockIcon />
        </Tooltip>
      </Box>
      <DialogContent sx={{ pt: 1, px: { xs: 1, sm: 3 } }}>
        {renewPlanOnSuccess && (
          <Typography
            sx={{
              mb: 1,
            }}
          >
            You must have a valid payment method to renew your plan.
          </Typography>
        )}
        <PaymentElement
          options={{
            layout: "auto",
            readOnly: submitting,
            terms: {
              card: "never",
              applePay: "never",
              googlePay: "never",
            },
          }}
        />
        {paymentErrorMessage && (
          <Card
            sx={{
              px: 2,
              py: 0.5,
              mt: 2,
              backgroundColor: (theme) => alpha(theme.palette.error.main, 0.2),
              borderColor: (theme) => theme.palette.error.main,
              display: "flex",
              alignItems: "center",
            }}
            variant="outlined"
          >
            <WarningRoundedIcon fontSize="inherit" color="error" />
            <Typography sx={{ ml: 1 }}>{paymentErrorMessage}</Typography>
          </Card>
        )}
        {validationMessage && (
          <Card
            sx={{
              px: 2,
              py: 0.5,
              mt: 2,
              backgroundColor: (theme) => alpha(theme.palette.error.main, 0.2),
              borderColor: (theme) => theme.palette.error.main,
              display: "flex",
              alignItems: "center",
            }}
            variant="outlined"
          >
            <WarningRoundedIcon fontSize="inherit" color="error" />
            <Typography sx={{ ml: 1 }}>{validationMessage}</Typography>
          </Card>
        )}
        <Typography
          variant="body2"
          sx={{ color: (theme) => theme.palette.text.secondary, my: 1 }}
        >
          By providing your card information, you allow trainwell to charge your
          card for future payments in accordance with our{" "}
          <Link
            color="inherit"
            href="https://www.trainwell.net/terms-and-conditions"
          >
            terms
          </Link>
          .
        </Typography>
        {!renewPlanOnSuccess && (
          <FormGroup>
            <FormControlLabel
              disabled={!defaultPaymentMethod}
              control={
                <Checkbox
                  checked={makeDefault}
                  onChange={(event) => {
                    setMakeDefault(event.target.checked);
                  }}
                />
              }
              label="Use as default payment method"
            />
          </FormGroup>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            handleClose();
          }}
          disabled={submitting}
        >
          Close
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            addPaymentMethod(makeDefault);
          }}
          loading={submitting || !stripe}
          startIcon={<AddRoundedIcon />}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
