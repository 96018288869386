import { Navigate } from "@tanstack/react-router";
import { type JSX } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import LoadingPage from "./LoadingPage";

interface Props {
  children: JSX.Element;
}

export default function WithAuth({ children }: Props) {
  const authType = useAppSelector((state) => state.auth.authType);
  const authSessionStatus = useAppSelector(
    (state) => state.auth.authSessionStatus,
  );
  const client = useAppSelector((state) => state.client.client);

  if (authType === "weak") {
    return <Navigate to={"/sign-in"} />;
  }

  if (authType === "loading") {
    return <LoadingPage message="Loading auth" />;
  }

  if (
    authType === "session" &&
    (authSessionStatus === "loading" || authSessionStatus === "idle")
  ) {
    return <LoadingPage message="Loading session auth" />;
  }

  if (authType === "session" && authSessionStatus === "failed") {
    return <Navigate to={"/sign-in"} />;
  }

  if (!client?.user_id) {
    return <LoadingPage message="Loading client" />;
  }

  return children;
}
