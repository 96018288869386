/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as SurveyImport } from './routes/survey'
import { Route as SignInImport } from './routes/sign-in'
import { Route as FinishSignInImport } from './routes/finish-sign-in'
import { Route as DownloadAppImport } from './routes/download-app'
import { Route as AprilFoolsImport } from './routes/april-fools'
import { Route as LayoutImport } from './routes/_layout'
import { Route as LayoutIndexImport } from './routes/_layout.index'
import { Route as VerifySessionSessionIdImport } from './routes/verify-session.$sessionId'
import { Route as LayoutUpgradeAccountImport } from './routes/_layout.upgrade-account'
import { Route as LayoutTipCoachImport } from './routes/_layout.tip-coach'
import { Route as LayoutScheduleCallImport } from './routes/_layout.schedule-call'
import { Route as LayoutScheduleImport } from './routes/_layout.schedule'
import { Route as LayoutPaymentMethodValidationImport } from './routes/_layout.payment-method-validation'
import { Route as LayoutMoveWorkoutsImport } from './routes/_layout.move-workouts'
import { Route as LayoutMondaySurveyImport } from './routes/_layout.monday-survey'
import { Route as LayoutLiveTrainingImport } from './routes/_layout.live-training'
import { Route as LayoutDoneImport } from './routes/_layout.done'
import { Route as LayoutChangeCoachImport } from './routes/_layout.change-coach'
import { Route as LayoutCallsImport } from './routes/_layout.calls'
import { Route as LayoutBillingImport } from './routes/_layout.billing'
import { Route as VerifySessionSessionIdRedirectImport } from './routes/verify-session.$sessionId.$redirect'
import { Route as LayoutRescheduleEventIdImport } from './routes/_layout.reschedule.$eventId'
import { Route as LayoutCancelEventIdImport } from './routes/_layout.cancel.$eventId'

// Create/Update Routes

const SurveyRoute = SurveyImport.update({
  id: '/survey',
  path: '/survey',
  getParentRoute: () => rootRoute,
} as any)

const SignInRoute = SignInImport.update({
  id: '/sign-in',
  path: '/sign-in',
  getParentRoute: () => rootRoute,
} as any)

const FinishSignInRoute = FinishSignInImport.update({
  id: '/finish-sign-in',
  path: '/finish-sign-in',
  getParentRoute: () => rootRoute,
} as any)

const DownloadAppRoute = DownloadAppImport.update({
  id: '/download-app',
  path: '/download-app',
  getParentRoute: () => rootRoute,
} as any)

const AprilFoolsRoute = AprilFoolsImport.update({
  id: '/april-fools',
  path: '/april-fools',
  getParentRoute: () => rootRoute,
} as any)

const LayoutRoute = LayoutImport.update({
  id: '/_layout',
  getParentRoute: () => rootRoute,
} as any)

const LayoutIndexRoute = LayoutIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => LayoutRoute,
} as any)

const VerifySessionSessionIdRoute = VerifySessionSessionIdImport.update({
  id: '/verify-session/$sessionId',
  path: '/verify-session/$sessionId',
  getParentRoute: () => rootRoute,
} as any)

const LayoutUpgradeAccountRoute = LayoutUpgradeAccountImport.update({
  id: '/upgrade-account',
  path: '/upgrade-account',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutTipCoachRoute = LayoutTipCoachImport.update({
  id: '/tip-coach',
  path: '/tip-coach',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutScheduleCallRoute = LayoutScheduleCallImport.update({
  id: '/schedule-call',
  path: '/schedule-call',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutScheduleRoute = LayoutScheduleImport.update({
  id: '/schedule',
  path: '/schedule',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutPaymentMethodValidationRoute =
  LayoutPaymentMethodValidationImport.update({
    id: '/payment-method-validation',
    path: '/payment-method-validation',
    getParentRoute: () => LayoutRoute,
  } as any)

const LayoutMoveWorkoutsRoute = LayoutMoveWorkoutsImport.update({
  id: '/move-workouts',
  path: '/move-workouts',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutMondaySurveyRoute = LayoutMondaySurveyImport.update({
  id: '/monday-survey',
  path: '/monday-survey',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutLiveTrainingRoute = LayoutLiveTrainingImport.update({
  id: '/live-training',
  path: '/live-training',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutDoneRoute = LayoutDoneImport.update({
  id: '/done',
  path: '/done',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutChangeCoachRoute = LayoutChangeCoachImport.update({
  id: '/change-coach',
  path: '/change-coach',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutCallsRoute = LayoutCallsImport.update({
  id: '/calls',
  path: '/calls',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutBillingRoute = LayoutBillingImport.update({
  id: '/billing',
  path: '/billing',
  getParentRoute: () => LayoutRoute,
} as any)

const VerifySessionSessionIdRedirectRoute =
  VerifySessionSessionIdRedirectImport.update({
    id: '/$redirect',
    path: '/$redirect',
    getParentRoute: () => VerifySessionSessionIdRoute,
  } as any)

const LayoutRescheduleEventIdRoute = LayoutRescheduleEventIdImport.update({
  id: '/reschedule/$eventId',
  path: '/reschedule/$eventId',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutCancelEventIdRoute = LayoutCancelEventIdImport.update({
  id: '/cancel/$eventId',
  path: '/cancel/$eventId',
  getParentRoute: () => LayoutRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_layout': {
      id: '/_layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof LayoutImport
      parentRoute: typeof rootRoute
    }
    '/april-fools': {
      id: '/april-fools'
      path: '/april-fools'
      fullPath: '/april-fools'
      preLoaderRoute: typeof AprilFoolsImport
      parentRoute: typeof rootRoute
    }
    '/download-app': {
      id: '/download-app'
      path: '/download-app'
      fullPath: '/download-app'
      preLoaderRoute: typeof DownloadAppImport
      parentRoute: typeof rootRoute
    }
    '/finish-sign-in': {
      id: '/finish-sign-in'
      path: '/finish-sign-in'
      fullPath: '/finish-sign-in'
      preLoaderRoute: typeof FinishSignInImport
      parentRoute: typeof rootRoute
    }
    '/sign-in': {
      id: '/sign-in'
      path: '/sign-in'
      fullPath: '/sign-in'
      preLoaderRoute: typeof SignInImport
      parentRoute: typeof rootRoute
    }
    '/survey': {
      id: '/survey'
      path: '/survey'
      fullPath: '/survey'
      preLoaderRoute: typeof SurveyImport
      parentRoute: typeof rootRoute
    }
    '/_layout/billing': {
      id: '/_layout/billing'
      path: '/billing'
      fullPath: '/billing'
      preLoaderRoute: typeof LayoutBillingImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/calls': {
      id: '/_layout/calls'
      path: '/calls'
      fullPath: '/calls'
      preLoaderRoute: typeof LayoutCallsImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/change-coach': {
      id: '/_layout/change-coach'
      path: '/change-coach'
      fullPath: '/change-coach'
      preLoaderRoute: typeof LayoutChangeCoachImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/done': {
      id: '/_layout/done'
      path: '/done'
      fullPath: '/done'
      preLoaderRoute: typeof LayoutDoneImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/live-training': {
      id: '/_layout/live-training'
      path: '/live-training'
      fullPath: '/live-training'
      preLoaderRoute: typeof LayoutLiveTrainingImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/monday-survey': {
      id: '/_layout/monday-survey'
      path: '/monday-survey'
      fullPath: '/monday-survey'
      preLoaderRoute: typeof LayoutMondaySurveyImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/move-workouts': {
      id: '/_layout/move-workouts'
      path: '/move-workouts'
      fullPath: '/move-workouts'
      preLoaderRoute: typeof LayoutMoveWorkoutsImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/payment-method-validation': {
      id: '/_layout/payment-method-validation'
      path: '/payment-method-validation'
      fullPath: '/payment-method-validation'
      preLoaderRoute: typeof LayoutPaymentMethodValidationImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/schedule': {
      id: '/_layout/schedule'
      path: '/schedule'
      fullPath: '/schedule'
      preLoaderRoute: typeof LayoutScheduleImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/schedule-call': {
      id: '/_layout/schedule-call'
      path: '/schedule-call'
      fullPath: '/schedule-call'
      preLoaderRoute: typeof LayoutScheduleCallImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/tip-coach': {
      id: '/_layout/tip-coach'
      path: '/tip-coach'
      fullPath: '/tip-coach'
      preLoaderRoute: typeof LayoutTipCoachImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/upgrade-account': {
      id: '/_layout/upgrade-account'
      path: '/upgrade-account'
      fullPath: '/upgrade-account'
      preLoaderRoute: typeof LayoutUpgradeAccountImport
      parentRoute: typeof LayoutImport
    }
    '/verify-session/$sessionId': {
      id: '/verify-session/$sessionId'
      path: '/verify-session/$sessionId'
      fullPath: '/verify-session/$sessionId'
      preLoaderRoute: typeof VerifySessionSessionIdImport
      parentRoute: typeof rootRoute
    }
    '/_layout/': {
      id: '/_layout/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof LayoutIndexImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/cancel/$eventId': {
      id: '/_layout/cancel/$eventId'
      path: '/cancel/$eventId'
      fullPath: '/cancel/$eventId'
      preLoaderRoute: typeof LayoutCancelEventIdImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/reschedule/$eventId': {
      id: '/_layout/reschedule/$eventId'
      path: '/reschedule/$eventId'
      fullPath: '/reschedule/$eventId'
      preLoaderRoute: typeof LayoutRescheduleEventIdImport
      parentRoute: typeof LayoutImport
    }
    '/verify-session/$sessionId/$redirect': {
      id: '/verify-session/$sessionId/$redirect'
      path: '/$redirect'
      fullPath: '/verify-session/$sessionId/$redirect'
      preLoaderRoute: typeof VerifySessionSessionIdRedirectImport
      parentRoute: typeof VerifySessionSessionIdImport
    }
  }
}

// Create and export the route tree

interface LayoutRouteChildren {
  LayoutBillingRoute: typeof LayoutBillingRoute
  LayoutCallsRoute: typeof LayoutCallsRoute
  LayoutChangeCoachRoute: typeof LayoutChangeCoachRoute
  LayoutDoneRoute: typeof LayoutDoneRoute
  LayoutLiveTrainingRoute: typeof LayoutLiveTrainingRoute
  LayoutMondaySurveyRoute: typeof LayoutMondaySurveyRoute
  LayoutMoveWorkoutsRoute: typeof LayoutMoveWorkoutsRoute
  LayoutPaymentMethodValidationRoute: typeof LayoutPaymentMethodValidationRoute
  LayoutScheduleRoute: typeof LayoutScheduleRoute
  LayoutScheduleCallRoute: typeof LayoutScheduleCallRoute
  LayoutTipCoachRoute: typeof LayoutTipCoachRoute
  LayoutUpgradeAccountRoute: typeof LayoutUpgradeAccountRoute
  LayoutIndexRoute: typeof LayoutIndexRoute
  LayoutCancelEventIdRoute: typeof LayoutCancelEventIdRoute
  LayoutRescheduleEventIdRoute: typeof LayoutRescheduleEventIdRoute
}

const LayoutRouteChildren: LayoutRouteChildren = {
  LayoutBillingRoute: LayoutBillingRoute,
  LayoutCallsRoute: LayoutCallsRoute,
  LayoutChangeCoachRoute: LayoutChangeCoachRoute,
  LayoutDoneRoute: LayoutDoneRoute,
  LayoutLiveTrainingRoute: LayoutLiveTrainingRoute,
  LayoutMondaySurveyRoute: LayoutMondaySurveyRoute,
  LayoutMoveWorkoutsRoute: LayoutMoveWorkoutsRoute,
  LayoutPaymentMethodValidationRoute: LayoutPaymentMethodValidationRoute,
  LayoutScheduleRoute: LayoutScheduleRoute,
  LayoutScheduleCallRoute: LayoutScheduleCallRoute,
  LayoutTipCoachRoute: LayoutTipCoachRoute,
  LayoutUpgradeAccountRoute: LayoutUpgradeAccountRoute,
  LayoutIndexRoute: LayoutIndexRoute,
  LayoutCancelEventIdRoute: LayoutCancelEventIdRoute,
  LayoutRescheduleEventIdRoute: LayoutRescheduleEventIdRoute,
}

const LayoutRouteWithChildren =
  LayoutRoute._addFileChildren(LayoutRouteChildren)

interface VerifySessionSessionIdRouteChildren {
  VerifySessionSessionIdRedirectRoute: typeof VerifySessionSessionIdRedirectRoute
}

const VerifySessionSessionIdRouteChildren: VerifySessionSessionIdRouteChildren =
  {
    VerifySessionSessionIdRedirectRoute: VerifySessionSessionIdRedirectRoute,
  }

const VerifySessionSessionIdRouteWithChildren =
  VerifySessionSessionIdRoute._addFileChildren(
    VerifySessionSessionIdRouteChildren,
  )

export interface FileRoutesByFullPath {
  '': typeof LayoutRouteWithChildren
  '/april-fools': typeof AprilFoolsRoute
  '/download-app': typeof DownloadAppRoute
  '/finish-sign-in': typeof FinishSignInRoute
  '/sign-in': typeof SignInRoute
  '/survey': typeof SurveyRoute
  '/billing': typeof LayoutBillingRoute
  '/calls': typeof LayoutCallsRoute
  '/change-coach': typeof LayoutChangeCoachRoute
  '/done': typeof LayoutDoneRoute
  '/live-training': typeof LayoutLiveTrainingRoute
  '/monday-survey': typeof LayoutMondaySurveyRoute
  '/move-workouts': typeof LayoutMoveWorkoutsRoute
  '/payment-method-validation': typeof LayoutPaymentMethodValidationRoute
  '/schedule': typeof LayoutScheduleRoute
  '/schedule-call': typeof LayoutScheduleCallRoute
  '/tip-coach': typeof LayoutTipCoachRoute
  '/upgrade-account': typeof LayoutUpgradeAccountRoute
  '/verify-session/$sessionId': typeof VerifySessionSessionIdRouteWithChildren
  '/': typeof LayoutIndexRoute
  '/cancel/$eventId': typeof LayoutCancelEventIdRoute
  '/reschedule/$eventId': typeof LayoutRescheduleEventIdRoute
  '/verify-session/$sessionId/$redirect': typeof VerifySessionSessionIdRedirectRoute
}

export interface FileRoutesByTo {
  '/april-fools': typeof AprilFoolsRoute
  '/download-app': typeof DownloadAppRoute
  '/finish-sign-in': typeof FinishSignInRoute
  '/sign-in': typeof SignInRoute
  '/survey': typeof SurveyRoute
  '/billing': typeof LayoutBillingRoute
  '/calls': typeof LayoutCallsRoute
  '/change-coach': typeof LayoutChangeCoachRoute
  '/done': typeof LayoutDoneRoute
  '/live-training': typeof LayoutLiveTrainingRoute
  '/monday-survey': typeof LayoutMondaySurveyRoute
  '/move-workouts': typeof LayoutMoveWorkoutsRoute
  '/payment-method-validation': typeof LayoutPaymentMethodValidationRoute
  '/schedule': typeof LayoutScheduleRoute
  '/schedule-call': typeof LayoutScheduleCallRoute
  '/tip-coach': typeof LayoutTipCoachRoute
  '/upgrade-account': typeof LayoutUpgradeAccountRoute
  '/verify-session/$sessionId': typeof VerifySessionSessionIdRouteWithChildren
  '/': typeof LayoutIndexRoute
  '/cancel/$eventId': typeof LayoutCancelEventIdRoute
  '/reschedule/$eventId': typeof LayoutRescheduleEventIdRoute
  '/verify-session/$sessionId/$redirect': typeof VerifySessionSessionIdRedirectRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_layout': typeof LayoutRouteWithChildren
  '/april-fools': typeof AprilFoolsRoute
  '/download-app': typeof DownloadAppRoute
  '/finish-sign-in': typeof FinishSignInRoute
  '/sign-in': typeof SignInRoute
  '/survey': typeof SurveyRoute
  '/_layout/billing': typeof LayoutBillingRoute
  '/_layout/calls': typeof LayoutCallsRoute
  '/_layout/change-coach': typeof LayoutChangeCoachRoute
  '/_layout/done': typeof LayoutDoneRoute
  '/_layout/live-training': typeof LayoutLiveTrainingRoute
  '/_layout/monday-survey': typeof LayoutMondaySurveyRoute
  '/_layout/move-workouts': typeof LayoutMoveWorkoutsRoute
  '/_layout/payment-method-validation': typeof LayoutPaymentMethodValidationRoute
  '/_layout/schedule': typeof LayoutScheduleRoute
  '/_layout/schedule-call': typeof LayoutScheduleCallRoute
  '/_layout/tip-coach': typeof LayoutTipCoachRoute
  '/_layout/upgrade-account': typeof LayoutUpgradeAccountRoute
  '/verify-session/$sessionId': typeof VerifySessionSessionIdRouteWithChildren
  '/_layout/': typeof LayoutIndexRoute
  '/_layout/cancel/$eventId': typeof LayoutCancelEventIdRoute
  '/_layout/reschedule/$eventId': typeof LayoutRescheduleEventIdRoute
  '/verify-session/$sessionId/$redirect': typeof VerifySessionSessionIdRedirectRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/april-fools'
    | '/download-app'
    | '/finish-sign-in'
    | '/sign-in'
    | '/survey'
    | '/billing'
    | '/calls'
    | '/change-coach'
    | '/done'
    | '/live-training'
    | '/monday-survey'
    | '/move-workouts'
    | '/payment-method-validation'
    | '/schedule'
    | '/schedule-call'
    | '/tip-coach'
    | '/upgrade-account'
    | '/verify-session/$sessionId'
    | '/'
    | '/cancel/$eventId'
    | '/reschedule/$eventId'
    | '/verify-session/$sessionId/$redirect'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/april-fools'
    | '/download-app'
    | '/finish-sign-in'
    | '/sign-in'
    | '/survey'
    | '/billing'
    | '/calls'
    | '/change-coach'
    | '/done'
    | '/live-training'
    | '/monday-survey'
    | '/move-workouts'
    | '/payment-method-validation'
    | '/schedule'
    | '/schedule-call'
    | '/tip-coach'
    | '/upgrade-account'
    | '/verify-session/$sessionId'
    | '/'
    | '/cancel/$eventId'
    | '/reschedule/$eventId'
    | '/verify-session/$sessionId/$redirect'
  id:
    | '__root__'
    | '/_layout'
    | '/april-fools'
    | '/download-app'
    | '/finish-sign-in'
    | '/sign-in'
    | '/survey'
    | '/_layout/billing'
    | '/_layout/calls'
    | '/_layout/change-coach'
    | '/_layout/done'
    | '/_layout/live-training'
    | '/_layout/monday-survey'
    | '/_layout/move-workouts'
    | '/_layout/payment-method-validation'
    | '/_layout/schedule'
    | '/_layout/schedule-call'
    | '/_layout/tip-coach'
    | '/_layout/upgrade-account'
    | '/verify-session/$sessionId'
    | '/_layout/'
    | '/_layout/cancel/$eventId'
    | '/_layout/reschedule/$eventId'
    | '/verify-session/$sessionId/$redirect'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  LayoutRoute: typeof LayoutRouteWithChildren
  AprilFoolsRoute: typeof AprilFoolsRoute
  DownloadAppRoute: typeof DownloadAppRoute
  FinishSignInRoute: typeof FinishSignInRoute
  SignInRoute: typeof SignInRoute
  SurveyRoute: typeof SurveyRoute
  VerifySessionSessionIdRoute: typeof VerifySessionSessionIdRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  LayoutRoute: LayoutRouteWithChildren,
  AprilFoolsRoute: AprilFoolsRoute,
  DownloadAppRoute: DownloadAppRoute,
  FinishSignInRoute: FinishSignInRoute,
  SignInRoute: SignInRoute,
  SurveyRoute: SurveyRoute,
  VerifySessionSessionIdRoute: VerifySessionSessionIdRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_layout",
        "/april-fools",
        "/download-app",
        "/finish-sign-in",
        "/sign-in",
        "/survey",
        "/verify-session/$sessionId"
      ]
    },
    "/_layout": {
      "filePath": "_layout.tsx",
      "children": [
        "/_layout/billing",
        "/_layout/calls",
        "/_layout/change-coach",
        "/_layout/done",
        "/_layout/live-training",
        "/_layout/monday-survey",
        "/_layout/move-workouts",
        "/_layout/payment-method-validation",
        "/_layout/schedule",
        "/_layout/schedule-call",
        "/_layout/tip-coach",
        "/_layout/upgrade-account",
        "/_layout/",
        "/_layout/cancel/$eventId",
        "/_layout/reschedule/$eventId"
      ]
    },
    "/april-fools": {
      "filePath": "april-fools.tsx"
    },
    "/download-app": {
      "filePath": "download-app.tsx"
    },
    "/finish-sign-in": {
      "filePath": "finish-sign-in.tsx"
    },
    "/sign-in": {
      "filePath": "sign-in.tsx"
    },
    "/survey": {
      "filePath": "survey.tsx"
    },
    "/_layout/billing": {
      "filePath": "_layout.billing.tsx",
      "parent": "/_layout"
    },
    "/_layout/calls": {
      "filePath": "_layout.calls.tsx",
      "parent": "/_layout"
    },
    "/_layout/change-coach": {
      "filePath": "_layout.change-coach.tsx",
      "parent": "/_layout"
    },
    "/_layout/done": {
      "filePath": "_layout.done.tsx",
      "parent": "/_layout"
    },
    "/_layout/live-training": {
      "filePath": "_layout.live-training.tsx",
      "parent": "/_layout"
    },
    "/_layout/monday-survey": {
      "filePath": "_layout.monday-survey.tsx",
      "parent": "/_layout"
    },
    "/_layout/move-workouts": {
      "filePath": "_layout.move-workouts.tsx",
      "parent": "/_layout"
    },
    "/_layout/payment-method-validation": {
      "filePath": "_layout.payment-method-validation.tsx",
      "parent": "/_layout"
    },
    "/_layout/schedule": {
      "filePath": "_layout.schedule.tsx",
      "parent": "/_layout"
    },
    "/_layout/schedule-call": {
      "filePath": "_layout.schedule-call.tsx",
      "parent": "/_layout"
    },
    "/_layout/tip-coach": {
      "filePath": "_layout.tip-coach.tsx",
      "parent": "/_layout"
    },
    "/_layout/upgrade-account": {
      "filePath": "_layout.upgrade-account.tsx",
      "parent": "/_layout"
    },
    "/verify-session/$sessionId": {
      "filePath": "verify-session.$sessionId.tsx",
      "children": [
        "/verify-session/$sessionId/$redirect"
      ]
    },
    "/_layout/": {
      "filePath": "_layout.index.tsx",
      "parent": "/_layout"
    },
    "/_layout/cancel/$eventId": {
      "filePath": "_layout.cancel.$eventId.tsx",
      "parent": "/_layout"
    },
    "/_layout/reschedule/$eventId": {
      "filePath": "_layout.reschedule.$eventId.tsx",
      "parent": "/_layout"
    },
    "/verify-session/$sessionId/$redirect": {
      "filePath": "verify-session.$sessionId.$redirect.tsx",
      "parent": "/verify-session/$sessionId"
    }
  }
}
ROUTE_MANIFEST_END */
