import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import { Box, Button } from "@mui/material";
import { Footer } from "@trainwell/ui";
import { RecaptchaVerifier, getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { EmailDialog } from "./EmailDialog";
import { PhoneNumberDialog } from "./PhoneNumberDialog";

interface Props {
  disableRedirect?: boolean;
}

export function SignInPage({ disableRedirect }: Props) {
  const [phoneDialodOpen, setPhoneDialogOpen] = useState(false);
  const [emailDialodOpen, setEmailDialogOpen] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    auth.useDeviceLanguage();

    // @ts-expect-error
    window.recaptchaVerifier = new RecaptchaVerifier(auth, "sign-in-button", {
      size: "invisible",
      callback: () => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        console.log("Captcha solved");
        // onSignInSubmit();
      },
    });
  }, []);

  return (
    <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          flex: 1,
          minHeight: "300px",
        }}
      >
        <Box sx={{ mb: 8 }}>
          <img
            src="/assets/trainwell-wordmark.svg"
            alt="Logo"
            height={90}
            width={336}
          />
        </Box>
        <Button
          id="sign-in-button"
          onClick={() => {
            setPhoneDialogOpen(true);
          }}
          sx={{ mb: 2 }}
          startIcon={<PhoneRoundedIcon />}
        >
          Sign in with phone number
        </Button>
        <Button
          variant="text"
          size="small"
          onClick={() => {
            setEmailDialogOpen(true);
          }}
          startIcon={<EmailRoundedIcon />}
        >
          Sign in with email
        </Button>
        <PhoneNumberDialog
          open={phoneDialodOpen}
          onClose={() => {
            setPhoneDialogOpen(false);
          }}
          disableRedirect={disableRedirect}
        />
        <EmailDialog
          open={emailDialodOpen}
          onClose={() => {
            setEmailDialogOpen(false);
          }}
        />
      </Box>
      <Footer />
    </Box>
  );
}
