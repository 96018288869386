import { useEffect, useState } from "react";

// https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  const [isLandscape, setIsLandscape] = useState(
    windowDimensions.height < windowDimensions.width,
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setIsLandscape(windowDimensions.height < windowDimensions.width);
  }, [windowDimensions]);

  return { ...windowDimensions, isLandscape };
}
