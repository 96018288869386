import {
  attachClosestEdge,
  extractClosestEdge,
  type Edge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";
import { Box, Card, IconButton, Stack, Typography } from "@mui/material";
import type { HabitTask, HabitTaskShadow } from "@trainwell/features/legacy";
import { format, parse } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { DragPreview } from "src/components/common/DragPreview";
import { DropIndicator } from "src/components/common/DropIndicator";

interface Props {
  index: number;
  workoutTasks: (HabitTask | HabitTaskShadow)[];
  date: string;
}

export function WorkoutGroup({ index, workoutTasks, date }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [_isDraggedOver, setIsDraggedOver] = useState(false);
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
  const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(
    null,
  );

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const data = {
      type: "workout_group",
      date: date,
      index: index,
    };

    return combine(
      draggable({
        element: element,
        getInitialData: () => data,
        onDragStart: () => {
          setDragging(true);
        },
        onDrop: () => {
          setDragging(false);
        },
        onGenerateDragPreview({ nativeSetDragImage }) {
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: pointerOutsideOfPreview({
              x: "16px",
              y: "8px",
            }),
            render({ container }) {
              setPreviewContainer(container);
            },
          });
        },
      }),
      dropTargetForElements({
        element,
        // onDragEnter: ({ source }) => {
        //   if (source.data.type !== "phase_day") {
        //     setIsDraggedOver(true);
        //   }
        // },
        // canDrop({ source }) {
        //   return source.data.type === "phase_day";
        // },
        getData({ input }) {
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ["top", "bottom"],
          });
        },
        onDrag({ self, source }) {
          const isSource = source.element === element;
          if (isSource) {
            setClosestEdge(null);
            return;
          }

          const closestEdge = extractClosestEdge(self.data);

          const sourceIndex = source.data.index;
          if (typeof sourceIndex !== "number") {
            return;
          }

          const isItemBeforeSource = index === sourceIndex - 1;
          const isItemAfterSource = index === sourceIndex + 1;

          const isDropIndicatorHidden =
            (isItemBeforeSource && closestEdge === "bottom") ||
            (isItemAfterSource && closestEdge === "top");

          if (isDropIndicatorHidden) {
            setClosestEdge(null);
            return;
          }

          setClosestEdge(closestEdge);
        },
        onDragLeave() {
          setClosestEdge(null);
          setIsDraggedOver(false);
        },
        onDrop() {
          setClosestEdge(null);
          setIsDraggedOver(false);
        },
      }),
    );
  }, [date, index]);

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          ref={ref}
          style={{
            opacity: dragging ? 0.5 : 1,
          }}
        >
          <Card
            variant="outlined"
            sx={{ p: 2, display: "flex", alignItems: "center" }}
          >
            <IconButton sx={{ mr: 1 }}>
              <DragIndicatorRoundedIcon />
            </IconButton>
            <Stack spacing={1}>
              {workoutTasks.map((workoutTask, i) => {
                const workoutTime = workoutTask.time_target
                  ? format(
                      parse(workoutTask.time_target, "HH:mm", new Date()),
                      "h:mm a",
                    )
                  : null;

                return (
                  <Box key={i}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        lineHeight: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      - {workoutTask.name}
                    </Typography>
                    {(workoutTime || workoutTask.duration_estimated) && (
                      <Typography
                        variant="caption"
                        sx={{
                          color: (theme) => theme.palette.text.secondary,
                          lineHeight: 1,
                        }}
                      >
                        {[
                          workoutTime,
                          workoutTask.duration
                            ? `${Math.round(workoutTask.duration / 60)}m`
                            : undefined,
                        ]
                          .filter(Boolean)
                          .join(" • ")}
                      </Typography>
                    )}
                  </Box>
                );
              })}
            </Stack>
          </Card>
        </div>
        {closestEdge && <DropIndicator edge={closestEdge} gap="0px" />}
      </div>
      {previewContainer
        ? createPortal(<DragPreview text={"Workouts"} />, previewContainer)
        : null}
    </>
  );
}
