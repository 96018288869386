import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { Box, Container, Typography } from "@mui/material";

export function AprilFoolsPage() {
  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
        p: 2,
      }}
    >
      <Typography variant="h1">Happy April Fools Day!</Typography>
      <Box
        sx={{
          width: 200,
          height: 200,
          my: 3,
        }}
      >
        <DotLottieReact
          src="/assets/lotties/april-fools.lottie"
          loop
          autoplay
        />
      </Box>
      <Typography>
        Your streak will remain the same! Burpees are only mandatory if your
        trainer says so.
        <br />
        <br />
        Thanks for being the best part of trainwell 💪
      </Typography>
    </Container>
  );
}
