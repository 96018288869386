import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Outlet } from "@tanstack/react-router";
import { getAuth, signOut } from "firebase/auth";
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { useAuth } from "src/hooks/useAuth";
import { showChat } from "src/lib/frontChat";
import AppProviders from "src/providers/AppProviders";
import {
  fetchActiveTestIds,
  setSessionId,
  startSession,
} from "src/slices/analyticsSlice";

export function Root() {
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => state.client.client);

  useAuth();

  // Handle coupons
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlCoupon = urlParams.get("coupon");
    const cachedCoupon = localStorage.getItem("cp_coupon");

    if (urlCoupon) {
      localStorage.setItem("cp_coupon", urlCoupon);

      console.log(`Coupon: found coupon in url: '${urlCoupon}'`);
    } else if (cachedCoupon) {
      console.log(`Coupon: found coupon in localStorage: '${cachedCoupon}'`);
    }

    const coupon = urlCoupon || cachedCoupon;

    if (coupon) {
      sessionStorage.setItem("couponId", coupon);
    }
  }, []);

  // Handle analytics session
  useEffect(() => {
    if (client?.user_id) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const test = urlParams.get("test");
      const storedSessionId = sessionStorage.getItem("sessionID");

      if (storedSessionId) {
        dispatch(setSessionId(storedSessionId));
        dispatch(
          fetchActiveTestIds({
            forceTestArray: test ? [test] : [],
            debugTest: undefined,
          }),
        );
      } else {
        dispatch(
          startSession({
            userId: client.user_id,
            forceTest: test ? [test] : [],
          }),
        );
      }
    }
  }, [client?.user_id]);

  const loadingFront = useRef(false);
  useEffect(() => {
    if (window && document && client?.user_id && !loadingFront.current) {
      loadingFront.current = true;

      const script = document.createElement("script");
      const body = document.getElementsByTagName("body")[0];
      script.src = "https://chat-assets.frontapp.com/v1/chat.bundle.js";
      body.appendChild(script);
      script.addEventListener("load", () => {
        showChat({
          clientName: client.full_name,
          userId: client.user_id,
        });
      });
    }
  }, [client?.user_id]);

  if (client?.account.membership.date_deleted) {
    return (
      <Dialog open>
        <DialogTitle>Account deleted</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This account has been deleted. Please contact support for more
            information. support@trainwell.net
          </DialogContentText>
          <DialogActions>
            <Button
              onClick={() => {
                const auth = getAuth();

                signOut(auth).then(() => {
                  console.log("Auth: logout successful");

                  localStorage.clear();
                  sessionStorage.clear();

                  location.reload();
                });
              }}
            >
              Log out
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <AppProviders>
      <Outlet />
    </AppProviders>
  );
}
