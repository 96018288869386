import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  useResumeSubscription,
  useSubscriptions,
} from "@trainwell/features/subscriptions";
import { useEffect, useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { hideChat, showChat } from "src/lib/frontChat";
import { formatCentsToDollars } from "src/lib/misc";

interface Props {
  planId: string;
  open: boolean;
  onClose: () => void;
}

export function ResumeDialog({ planId, open, onClose }: Props) {
  const [submitting, setSubmitting] = useState(false);
  const client = useAppSelector((state) => state.client.client);
  const resumeSubscription = useResumeSubscription();
  const { data: subscriptions } = useSubscriptions({
    userId: client?.user_id ?? "",
  });
  const plan = subscriptions?.find((plan) => plan.id === planId);

  useEffect(() => {
    if (open) {
      hideChat();
    } else {
      showChat({
        clientName: client?.full_name ?? "",
        userId: client?.user_id ?? "",
      });
    }
  }, [open]);

  function handleClose() {
    onClose();

    setSubmitting(false);
  }

  if (!plan) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (submitting) {
          return;
        }

        handleClose();
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Resume plan</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 1 }}>
          By resuming you will be charged{" "}
          {formatCentsToDollars(plan.price ?? 0)} immediately and will regain
          access to the trainwell app. Your billing period will reset to today.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            handleClose();
          }}
          disabled={submitting}
        >
          Close
        </Button>
        <Button
          onClick={() => {
            setSubmitting(true);

            resumeSubscription.mutate(plan.id, {
              onSuccess: () => {
                handleClose();
              },
            });
          }}
          loading={submitting}
        >
          Resume
        </Button>
      </DialogActions>
    </Dialog>
  );
}
