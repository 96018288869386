import { useLocation, useNavigate } from "@tanstack/react-router";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import { app } from "src/lib/firebase";
import { handleAuthToken } from "src/slices/authSlice";
import { useAppDispatch, useAppSelector } from "./stateHooks";

const weakAuthRoutes = [
  "sign-in",
  "finish-sign-in",
  "change-coach",
  "schedule-call",
  "schedule",
  "cancel",
  "reschedule",
  "done",
  "survey",
  "download-app",
  "verify-session",
  "calls",
];

const ignoredAuthRoutes = ["april-fools"];

export function useAuth() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const authType = useAppSelector((state) => state.auth.authType);
  const [didForceLogout, setDidForceLogout] = useState(false);

  useEffect(() => {
    console.log("Firebase app: ", app.name);

    if (authType === "session") {
      return;
    }

    if (window.location.pathname.includes("verify-session")) {
      // Session auth overrides token auth
      // https://trainwellfitness.slack.com/archives/C02SBEB5EF8/p1736459796587099
      return;
    }

    const auth = getAuth();

    const unsubscribeAuthChange = onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        console.log("Auth: sign in with uid ", uid);

        user.getIdTokenResult().then((idTokenResult) => {
          dispatch(handleAuthToken(idTokenResult))
            .unwrap()
            .then(() => {
              if (location.pathname.includes("login")) {
                console.log("Auth: Redirecting to home page");
                navigate({ to: "/" });
              }
            })
            .catch(() => {
              console.log("Auth: No userId found");

              logout();
            });
        });
      } else {
        console.log("Auth: sign out");

        if (
          !weakAuthRoutes.some((path) => location.pathname.includes(path)) &&
          !ignoredAuthRoutes.some((path) => location.pathname.includes(path))
        ) {
          console.log("Auth: redirect to sign in");
          navigate({ to: "/sign-in", replace: true });
        } else {
          console.log("Auth: not redirecting to sign in");
        }
      }
    });

    return () => {
      unsubscribeAuthChange();
    };
  }, []);

  useEffect(() => {
    if (
      authType === "weak" &&
      !didForceLogout &&
      !weakAuthRoutes.some((path) => location.pathname.includes(path)) &&
      !ignoredAuthRoutes.some((path) => location.pathname.includes(path))
    ) {
      setDidForceLogout(true);
      logout();
    }
  }, [location]);

  function logout() {
    navigate({ to: "/sign-in" });

    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log("Sign out: success");
      })
      .catch(() => {
        console.log("Sign out: fail");
      });

    localStorage.clear();
    sessionStorage.clear();
  }
}
